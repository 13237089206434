import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IMessage_Templates {
  id: number;
  template_text: string;
  variables?: string;
  operator_id?: number;
  message_type_id?: number;
  series_id?: number;
}

interface IMessage_TemplatesData {
  list?: Array<IMessage_Templates>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IMessage_TemplatesData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "success" },
};

const message_templatesSlice = createSlice({
  name: "message_templates",
  initialState,
  reducers: {
    setMessage_TemplatesList: (
      state,
      _action: PayloadAction<IMessage_TemplatesData>
    ) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetMessage_TemplatesToInit: (state) => {
      state = initialState;
    },
    setMessage_TemplatesMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const {
  setMessage_TemplatesList,
  resetMessage_TemplatesToInit,
  setMessage_TemplatesMessage,
} = message_templatesSlice.actions;

export default message_templatesSlice.reducer;
