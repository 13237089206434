import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetMailingsToInit, setMailingsMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteMailings, startMailing } from "services/mailingsService";
import { useUser } from "../../hooks/useUser";
import DatePicker from "react-datepicker";
import {getBalance} from "../../services/balanceService";
import {setBalance} from "../../redux/slices/template";

type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row) => void;
  handleRowShow: (row) => void;
  getData: (page, pageSize, searchKey, startDate?: any, endDate?: any) => void;
};
export const MailingsTable: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  handleRowShow,
  getData,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [rowData, setRowData] = useState(undefined);
  const rData = useSelector((state: RootState) => state.mailings);
  const handleSearch = (e) => {
    if (search.length > 0 || (startDate && endDate)) {
      getData(
        Constant.defaultPageNumber,
        Constant.defaultPageSize,
        search,
        startDate?.toLocaleDateString(),
        endDate?.toLocaleDateString()
      );
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(page, newPerPage, "");
  };

  const handlePageChange = (page) => {
    getData(page, rData.pageSize, "");
  };
  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };
  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      dispatch(resetMailingsToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    }
  }, [rData.list.length]);
  const handleReset = () => {
    setSearch("");
    dispatch(resetMailingsToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };
  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteMailings(rowData.id);
      if (response) {
        dispatch(resetMailingsToInit());
        dispatch(
          setMailingsMessage({
            text: "Удалено успешно",
            type: Constant.alertVariantSuccess,
          })
        );
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        setShowDelete(false);
      } else {
        dispatch(
          setMailingsMessage({
            text: "Ошибка!",
            type: Constant.alertVariantDanger,
          })
        );
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };
  const handleAddButtonClick = () => {
    dispatch(
      setMailingsMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    hideShowForm("add");
  };

  const handleAddManualButtonClick = () => {
    dispatch(
      setMailingsMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    hideShowForm("add-manual");
  };

  const handleShowMethod = (row) => {
    dispatch(
      setMailingsMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    handleRowShow(row);
  };

  const calcBalance = async () => {
    const data = await getBalance(
      JSON.parse(localStorage.getItem("userData")).id
    )

    dispatch(setBalance(data))
  }

  const handleRowStart = (row) => {
    startMailing(row.id).then((data: any) => {
      calcBalance();

      if (data.data) {
        return alert(data.data.message);
      }

      alert(data.message);
    });
  };

  const getStatusMessages = (status: number) => {
    switch (status) {
      case 0:
        return "Not Ready";
      case 1:
        return "Ready For Start";
      case 2:
        return "Ended";
    }
  };

  const { user } = useUser();

  let columns;

  if (user.is_admin === 1) {
    columns = [
      { name: "id", selector: (row) => row.id, sortable: true },
      { name: "Пользователь", selector: (row) => row.username, sortable: true },
      {
        name: "Имя рассылки",
        selector: (row) => row.description,
        sortable: true,
      },
      {
        name: "Статус",
        selector: (row) => getStatusMessages(row.status),
        sortable: true,
      },
      {
        name: "Стоимость рассылки",
        selector: (row) => row.cost,
        sortable: true,
      },
      {
        name: "Начало рассылки",
        selector: (row) =>
          row.date_started
            ? Intl.DateTimeFormat("ru-RU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }).format(new Date(row.date_started))
            : null,
        sortable: true,
        minWidth: "165px",
      },
      {
        name: "Конец рассылки",
        selector: (row) =>
          row.end_at
            ? Intl.DateTimeFormat("ru-RU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }).format(new Date(row.end_at))
            : null,
        sortable: true,
        minWidth: "165px",
      },
      {
        name: "Запланированные рассылки",
        selector: (row) =>
          row.scheduled_at
            ? Intl.DateTimeFormat("ru-RU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }).format(new Date(row.scheduled_at))
            : null,
        sortable: true,
        minWidth: "165px",
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowStart(row)}>
            Запустить
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleShowMethod(row)}>
            Посмотреть
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowDeleteClick(row)}>
            Удалить
          </Button>
        ),
      },
    ];
  } else {
    columns = [
      { name: "id", selector: (row) => row.id, sortable: true },
      {
        name: "Имя рассылки",
        selector: (row) => row.description,
        sortable: true,
      },
      {
        name: "Статус",
        selector: (row) => getStatusMessages(row.status),
        sortable: true,
      },
      {
        name: "Стоимость рассылки",
        selector: (row) => row.cost,
        sortable: true,
      },
      {
        name: "Начало рассылки",
        selector: (row) =>
          row.date_started
            ? Intl.DateTimeFormat("ru-RU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }).format(new Date(row.date_started))
            : null,
        sortable: true,
        minWidth: "165px",
      },
      {
        name: "Конец рассылки",
        selector: (row) =>
          row.end_at
            ? Intl.DateTimeFormat("ru-RU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }).format(new Date(row.end_at))
            : null,
        sortable: true,
        minWidth: "165px",
      },
      {
        name: "Запланированные рассылки",
        selector: (row) =>
          row.scheduled_at
            ? Intl.DateTimeFormat("ru-RU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }).format(new Date(row.scheduled_at))
            : null,
        sortable: true,
        minWidth: "165px",
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowStart(row)}>
            Start
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleShowMethod(row)}>
            Show
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowDeleteClick(row)}>
            Удалить
          </Button>
        ),
      },
    ];
  }
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Список рассылок ({rData.totalCount})
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}>
            <i className="fa fa-refresh"></i>
          </Button>
          <Button
            className="btn-icon-split float-right"
            onClick={handleAddManualButtonClick}>
            <span className="icon text-white-50">
              <i className="fas fa-add"></i>
            </span>
            <span className="text">Ручная отправка</span>
          </Button>
          <Button
            className="btn-icon-split float-right mr-3"
            onClick={handleAddButtonClick}>
            <span className="icon text-white-50">
              <i className="fas fa-add"></i>
            </span>
            <span className="text">Добавить новую</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Col
          className="ml-auto"
          md={3}
          xs={12}
          xl={3}>
          <InputGroup>
            <Form.Control
              placeholder="Поиск"
              aria-label="Search"
              aria-describedby="basic-search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              disabled={search.length <= 2}
              variant="dark"
              className="rounded-0 rounded-right"
              id="button-search"
              onClick={handleSearch}>
              Поиск
            </Button>
          </InputGroup>
        </Col>
        <div className="table-responsive">
          <DataTable
            noDataComponent="Нет записей для отображения"
            selectableRows={true}
            onSelectedRowsChange={handleRowSelection}
            paginationPerPage={Constant.defaultPageSize}
            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
            columns={columns}
            data={rData.list}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={rData.totalCount}
            className="table table-bordered"
            pagination
            paginationServer
            onChangePage={handlePageChange}></DataTable>
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative=" Отменить"
        buttonPositive="Удалить"
        title="Подтверждение удаления"
        show={showDelete}
        body={"Вы уверены?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
