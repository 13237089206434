import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetSender_NamesToInit, setSender_NamesMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteSender_Names } from "services/sender_namesService";
import operators from "../../redux/slices/operators";
import { ISender_Names } from "../../redux/slices/sender_names";
import { array } from "yup";
import Accordion from "react-bootstrap/Accordion";
import { genStringUUID } from "../../utils/genUUID";
import { useUser } from "../../hooks/useUser";

type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row) => void;
  getData: (page, pageSize, searchKey) => void;
};
export const Sender_NamesTable: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  getData,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const [preparedData, setPreparedData] = useState({});
  const rData = useSelector((state: RootState) => state.sender_names);

  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(page, newPerPage, "");
  };

  const handlePageChange = (page) => {
    getData(page, rData.pageSize, "");
  };

  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };

  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      dispatch(resetSender_NamesToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    }
  }, [rData.list.length]);

  const handleReset = () => {
    setSearch("");
    dispatch(resetSender_NamesToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };

  const { user } = useUser();

  useEffect(() => {
    if (rData.list.length < 1) return;

    if (user.is_admin === 1) {
      const pData: object = rData.list.reduce(
        (prevVal, currentValue, currentIndex) => {
          currentValue.providers.forEach((p) => {
            if (!prevVal[p.name]) {
              prevVal[p.name] = {};
            }

            currentValue.operators.forEach((o) => {
              if (!prevVal[p.name][o.operator_name]) {
                prevVal[p.name][o.operator_name] = [];
              }

              prevVal[p.name][o.operator_name].push({
                id: currentValue.id,
                sender_name: currentValue.sender_name,
                username: currentValue.id_Value,
              });
            });
          });

          return prevVal;
        },
        {}
      );

      console.log("pData", pData);

      setPreparedData(pData);
    }
  }, [rData]);

  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteSender_Names(rowData.id);
      if (response) {
        dispatch(resetSender_NamesToInit());
        dispatch(
          setSender_NamesMessage({
            text: "Удалено успешно",
            type: Constant.alertVariantSuccess,
          })
        );
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        setShowDelete(false);
      } else {
        dispatch(
          setSender_NamesMessage({
            text: "Ошибка!",
            type: Constant.alertVariantDanger,
          })
        );
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };
  const handleAddButtonClick = () => {
    dispatch(
      setSender_NamesMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    hideShowForm("add");
  };

  let columns;

  if (user.is_admin === 1) {
    columns = [
      { name: "id", selector: (row) => row.id, sortable: true },
      {
        name: "Имя отправителя",
        selector: (row) => row.sender_name,
        sortable: true,
      },
      {
        name: "Имя пользователя",
        selector: (row) => row.username,
        sortable: true,
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowEdit(row)}
          >
            Изменить
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowDeleteClick(row)}
          >
            Удалить
          </Button>
        ),
      },
    ];
  } else {
    columns = [
      { name: "id", selector: (row) => row.id, sortable: true },
      {
        name: "Имя отправителя",
        selector: (row) => row.sender_name,
        sortable: true,
      },
      {
        name: "Операторы",
        selector: (row) => row.operators.map((o) => o.operator_name).join(", "),
      },
    ];
  }
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Список имен ({rData.totalCount})
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}
          >
            <i className="fa fa-refresh"></i>
          </Button>
          {!user.is_user && (
            <Button
              className="btn-icon-split float-right"
              onClick={handleAddButtonClick}
            >
              <span className="icon text-white-50">
                <i className="fas fa-add"></i>
              </span>
              <span className="text">Добавить новое</span>
            </Button>
          )}
        </h6>
      </Card.Header>
      <Card.Body>
        <Col className="ml-auto" md={3} xs={12} xl={3}>
          <InputGroup>
            <Form.Control
              placeholder="Поиск"
              aria-label="Search"
              aria-describedby="basic-search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              disabled={search.length <= 2}
              variant="dark"
              className="rounded-0 rounded-right"
              id="button-search"
              onClick={handleSearch}
            >
              Поиск
            </Button>
          </InputGroup>
        </Col>
        <div className="table-responsive">
          {user.is_admin ? (
            <Accordion
              defaultActiveKey="0"
              style={{ gap: "8px", display: "flex", flexDirection: "column" }}
            >
              {Object.keys(preparedData).map((provider: string) => {
                const _providerKey = genStringUUID();
                const operators = preparedData[provider];

                return (
                  <Accordion>
                    <Accordion.Item eventKey={_providerKey}>
                      <Accordion.Header>
                        <Button variant="info">{provider}</Button>
                      </Accordion.Header>
                      <Accordion.Body>
                        {Object.keys(operators).map((operator: string) => {
                          const _operatorKey = genStringUUID();
                          const senderNames = operators[operator];

                          return (
                            <Accordion>
                              <Accordion.Item eventKey={_operatorKey}>
                                <Accordion.Header>
                                  <Button variant="danger">{operator}</Button>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <DataTable
                                    columns={columns}
                                    data={senderNames}
                                  ></DataTable>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })}
            </Accordion>
          ) : (
            <DataTable columns={columns} data={rData.list}></DataTable>
          )}
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative=" Отменить"
        buttonPositive="Удалить"
        title="Подтверждение удаления"
        show={showDelete}
        body={"Вы уверены?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
