import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITransactions {
  id: number;
  user_id?: number;
  amount?: number;
  date_transacted?: Date;
}

interface ITransactionsData {
  list?: Array<ITransactions>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: ITransactionsData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "success" },
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setTransactionsList: (state, _action: PayloadAction<ITransactionsData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetTransactionsToInit: (state) => {
      state = initialState;
    },
    setTransactionsMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const {
  setTransactionsList,
  resetTransactionsToInit,
  setTransactionsMessage,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
