import { APIService } from "services";

export const getProxies = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllProxy(pageNo, pageSize);
  } else {
    try {
      res = await searchProxy(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const addProxy = (data) => {
  return APIService.api().post(`/proxy`, data);
};
export const updateProxy = (id, data) => {
  return APIService.api().patch(`/proxy/${id}`, data);
};
export const getAllProxy = (pageNo, pageSize) => {
  return APIService.api().get(`/proxy/?pageNo=${pageNo}&pageSize=${pageSize}`);
};
export const getOneProxy = (id) => {
  return APIService.api().get(`/proxy/${id}`);
};
export const searchProxy = (searchKey, pageNo, pageSize) => {
  return APIService.api().get(
    `/proxy/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const deleteProxy = (id) => {
  return APIService.api().delete(`/proxy/${id}`);
};
