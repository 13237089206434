import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setRolesMessage } from "redux/actions";

import { useAppDispatch } from "redux/store";
import { addRoles, updateRoles } from "services/rolesService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { USER_ROLES } from "../../consts/auth.consts";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const RolesForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    role_name: "",
    role: USER_ROLES.USER,
  };
  const initialValue = action === "edit" ? row : iValue;

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async ({ role, ...values }) => {
      const roleValues = { ...values, [role]: true };

      if (action === "edit") {
        const response = await updateRoles(row.id, roleValues);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setRolesMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setRolesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addRoles(roleValues);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setRolesMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setRolesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      role_name: yup.string().required("role_name обязательное поле"),
      role: yup.string().required("role обязательное поле"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "add" ? "Добавление" : "Изменение"} Роли
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Назад к списку ролей</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Название роли</label>
            <Form.Control
              type="text"
              name="role_name"
              className="form-control"
              value={formik.values.role_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.role_name && !!formik.errors.role_name
              }
              isValid={
                !!formik.touched.role_name && !formik.errors.role_name
              }></Form.Control>
            {formik.errors.role_name && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.role_name}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mt-3">
            {Object.keys(USER_ROLES).map((key) => {
              const roleValue = USER_ROLES[key];

              return (
                <Form.Check
                  type="radio"
                  name={key}
                  aria-label={key}
                  label={key}
                  checked={formik.values.role === roleValue}
                  value={roleValue}
                  onChange={() => {
                    formik.setValues((vals) => ({
                      ...vals,
                      role: roleValue,
                    }));
                  }}
                  onBlur={formik.handleBlur}
                />
              );
            })}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
