import { APIService } from "services";
import { combineStrings } from "../utils/combineStrings";
import { makeQueryParams } from "../utils/makeQueryParams";

export const getSent_Messages = async (
  pageNo,
  pageSize,
  search,
  messageType?: number
) => {
  let res;

  if (search.length === 0) {
    res = await getAllSent_Messages(pageNo, pageSize, messageType);
  } else {
    try {
      res = await searchSent_Messages(search, pageNo, pageSize, messageType);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const addSent_Messages = (data) => {
  return APIService.api().post(`/sent_messages`, data);
};
export const updateSent_Messages = (id, data) => {
  return APIService.api().patch(`/sent_messages/${id}`, data);
};
export const getAllSent_Messages = (pageNo, pageSize, messageType?: number) => {
  const path = combineStrings(
    "/sent_messages/",
    makeQueryParams({
      pageNo,
      pageSize,
      message_type: messageType,
    })
  );

  return APIService.api().get(path);
};

export const getByMailingId = (mailing_id, pageNo, pageSize, search = "") => {
  const path = combineStrings(
    `/sent_messages/getby/mailing_id/${mailing_id}`,
    makeQueryParams({
      pageNo,
      pageSize,
      search,
    })
  );
  console.log(path);

  return APIService.api().get(path);
};
export const getOneSent_Messages = (id) => {
  return APIService.api().get(`/sent_messages/${id}`);
};
export const searchSent_Messages = (
  searchKey,
  pageNo,
  pageSize,
  messageType?: number
) => {
  const path = combineStrings(
    `/sent_messages/search/${searchKey}/`,
    makeQueryParams({
      pageNo,
      pageSize,
      message_type: messageType,
    })
  );

  return APIService.api().get(path);
};
export const deleteSent_Messages = (id) => {
  return APIService.api().delete(`/sent_messages/${id}`);
};
