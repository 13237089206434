import { useFormik } from "formik";
import React, { useEffect } from "react";

import { Button, Card, Form } from "react-bootstrap";
import {
  resetOperatorsToInit,
  setOperatorsList,
  setOperatorsMessage,
  setSender_NamesMessage,
} from "redux/actions";

import { useAppDispatch } from "redux/store";
import {
  addOperators,
  getOperators,
  updateOperators,
} from "services/operatorsService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const OperatorsForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = { id: 0, operator_name: "", is_moderated: 0, parent_id: null };
  const initialValue = action === "edit" ? row : iValue;

  const operatorsData = useSelector((state: RootState) => state.operators);

  useEffect(() => {
    if (
      operatorsData &&
      operatorsData.list &&
      operatorsData.list.length === 0
    ) {
      dispatch(resetOperatorsToInit());
      getOperators(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          response.records.push({
            id: null,
            operator_name: "Не выбрано",
          });

          console.log(response.records);

          dispatch(
            setOperatorsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "No Record Found For Operators",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateOperators(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setOperatorsMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setOperatorsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addOperators(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setOperatorsMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setOperatorsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      operator_name: yup.string().required("operator_name обязательное поле"),
      is_moderated: yup.boolean(),
      is_default: yup.boolean(),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Назад к списку операторов</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Название оператора</label>
            <Form.Control
              type="text"
              name="operator_name"
              className="form-control"
              value={formik.values.operator_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.operator_name && !!formik.errors.operator_name
              }
              isValid={
                !!formik.touched.operator_name && !formik.errors.operator_name
              }></Form.Control>
            {formik.errors.operator_name && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.operator_name}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Активный</label>
            <Form.Check
              className="ml-4 mb-4"
              name="is_moderated"
              checked={formik.values.is_moderated === 1}
              onChange={(e) => {
                formik.setFieldValue("is_moderated", e.target.checked ? 1 : 0);
              }}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.is_moderated && !!formik.errors.is_moderated
              }
              isValid={
                !!formik.touched.is_moderated && !formik.errors.is_moderated
              }></Form.Check>
            {formik.errors.is_moderated && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.is_moderated}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Дочерний оператор</label>
            <Form.Check
              className="ml-4 mb-4"
              name="is_default"
              checked={formik.values.is_default === 1}
              onChange={(e) => {
                formik.setFieldValue("is_default", e.target.checked ? 1 : 0);
              }}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.is_default && !!formik.errors.is_default
              }
              isValid={
                !!formik.touched.is_default && !formik.errors.is_default
              }></Form.Check>
            {formik.errors.is_moderated && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.is_moderated}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">
              Выберите основного оператора
            </label>
            <Form.Control
              as="select"
              name="parent_id"
              className="form-control"
              value={formik.values.operators}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.operators && !!formik.errors.operators
              }
              isValid={!!formik.touched.operators && !formik.errors.operators}>
              <option
                value="null"
                key={`operators-null`}>
                Не выбрано
              </option>
              {operatorsData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`operators-${i}`}>
                    {item.operator_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.operators && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.operators}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
