import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import users from "redux/slices/users";
import user_contacts from "redux/slices/user_contacts";
import moderated_operators from "redux/slices/moderated_operators";
import transactions from "redux/slices/transactions";
import step_tariffs from "redux/slices/step_tariffs";
import step_discounts from "redux/slices/step_discounts";
import sent_messages from "redux/slices/sent_messages";
import sender_names from "redux/slices/sender_names";
import roles from "redux/slices/roles";
import series from "./slices/series";
import operators from "redux/slices/operators";
import message_types from "redux/slices/message_types";
import message_templates from "redux/slices/message_templates";
import message_status from "redux/slices/message_status";
import mailings from "redux/slices/mailings";
import companies from "redux/slices/companies";
import sms_providers from "redux/slices/sms_providers";
import moderated_sender_names from "./slices/moderated_sender_names";
import proxies from "./slices/proxies";
import errorCodes from "./slices/error_reference/";
import loading_file_progress from "./slices/loading_file_progress";
import short_links from "./slices/short_links";

const rootReducer = combineReducers({
  template,
  authToken,
  users,
  user_contacts,
  transactions,
  step_tariffs,
  step_discounts,
  sent_messages,
  sender_names,
  roles,
  operators,
  message_types,
  message_templates,
  message_status,
  mailings,
  companies,
  sms_providers,
  moderated_operators,
  moderated_sender_names,
  proxies,
  errorCodes,
  series,
  loading_file_progress,
  short_links,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
