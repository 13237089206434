import { APIService } from "services";

export const getOperators = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllOperators(pageNo, pageSize);
  } else {
    try {
      res = await searchOperators(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const getModeratedOperators = async (pageNo, pageSize) => {
  let res;

  res = await getApiModeratedOperators(pageNo, pageSize);

  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const addOperators = (data) => {
  return APIService.api().post(`/operators`, data);
};
export const updateOperators = (id, data) => {
  return APIService.api().patch(`/operators/${id}`, data);
};
export const getAllOperators = (pageNo, pageSize) => {
  return APIService.api().get(
    `/operators/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getApiModeratedOperators = (pageNo, pageSize) => {
  return APIService.api().get(
    `/operators/moderated/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getOneOperators = (id) => {
  return APIService.api().get(`/operators/${id}`);
};
export const searchOperators = (searchKey, pageNo, pageSize) => {
  return APIService.api().get(
    `/operators/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const deleteOperators = (id) => {
  return APIService.api().delete(`/operators/${id}`);
};
