import React, { FC } from "react";
import { Navigate } from "react-router";
import { useUser } from "../../hooks/useUser";
import { isRoutePermitted, TUserRole } from "../../consts/auth.consts";

const AuthenticatedRoute: FC<{ element: any; roles?: TUserRole[] }> = ({
  element,
  roles,
}) => {
  const { user } = useUser();

  const isPermitted = !roles || isRoutePermitted(user, roles);

  if (isPermitted) {
    return element;
  } else {
    return (
      <Navigate
        to="/"
        replace
      />
    );
  }
};

export default AuthenticatedRoute;
