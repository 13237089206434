import { useFormik } from "formik";
import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { setRolesMessage } from "redux/actions";
import { useAppDispatch } from "redux/store";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { addProxy, updateProxy } from "../../services/proxiesService";
import { setProxiesList, setProxiesMessage } from "../../redux/slices/proxies";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const ProxiesForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    protocol: "socks5",
    host: "",
    login: "",
    password: "",
  };
  const initialValue = action === "edit" ? row : iValue;

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateProxy(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setProxiesMessage("Обновлено успешно"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setProxiesMessage("Ошибка!"));
        }
      } else if (action === "add") {
        const response = await addProxy(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(setProxiesMessage("Добавлено успешно!"));
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(setProxiesMessage("Ошибка!"));
        }
      }
    },
    validationSchema: yup.object({
      protocol: yup.string().required("Поле обязательно для заполнения"),
      host: yup.string().required("Поле обязательно для заполнения"),
      login: yup.string().required("Поле обязательно для заполнения"),
      password: yup.string().required("Поле обязательно для заполнения"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action} Прокси
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Назад к списку прокси</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Протокол</label>
            <Form.Control
              type="text"
              name="protocol"
              className="form-control"
              value={formik.values.protocol}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.protocol && !!formik.errors.protocol}
              isValid={
                !!formik.touched.protocol && !formik.errors.protocol
              }></Form.Control>
            {formik.errors.protocol && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.protocol}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Хост</label>
            <Form.Control
              type="text"
              name="host"
              className="form-control"
              value={formik.values.host}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.host && !!formik.errors.host}
              isValid={
                !!formik.touched.host && !formik.errors.host
              }></Form.Control>
            {formik.errors.host && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.host}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Логин</label>
            <Form.Control
              type="text"
              name="login"
              className="form-control"
              value={formik.values.login}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.login && !!formik.errors.login}
              isValid={
                !!formik.touched.login && !formik.errors.login
              }></Form.Control>
            {formik.errors.login && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.login}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Пароль</label>
            <Form.Control
              type="text"
              name="password"
              className="form-control"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.password && !!formik.errors.password}
              isValid={
                !!formik.touched.password && !formik.errors.password
              }></Form.Control>
            {formik.errors.password && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.password}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
