import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setMessage_StatusMessage } from "redux/actions";

import { useAppDispatch } from "redux/store";
import {
  addMessage_Status,
  updateMessage_Status,
} from "services/message_statusService";
import { Constant } from "template/Constant";
import * as yup from "yup";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const Message_StatusForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = { id: 0, status_name: "", to_pay: false };
  const initialValue = action === "edit" ? row : iValue;

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateMessage_Status(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setMessage_StatusMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setMessage_StatusMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addMessage_Status(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setMessage_StatusMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setMessage_StatusMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      status_name: yup.string().required("status_name это обязательное поле"),
      to_pay: yup.boolean().nullable(),
    }),
  });

  console.log(`formik.values.to_pay: ${formik.values.to_pay}`);
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "add" ? "Добавление" : "Изменение"} статуса сообщений
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Посмотреть статусы сообщений</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Название статуса</label>
            <Form.Control
              type="text"
              name="status_name"
              className="form-control"
              value={formik.values.status_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.status_name && !!formik.errors.status_name
              }
              isValid={
                !!formik.touched.status_name && !formik.errors.status_name
              }
            ></Form.Control>
            {formik.errors.status_name && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.status_name}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Check
              name="to_pay"
              label="заплатить"
              type="switch"
              value={formik.values.to_pay}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></Form.Check>
            {formik.errors.to_pay && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.to_pay}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <Button type="submit" className="float-right" variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
