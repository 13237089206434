import { TPrimitive } from "../types/shared.types";

export const makeQueryParams = (
  params: Record<PropertyKey, TPrimitive>,
): string => {
  const queryParams = new URLSearchParams();

  for (const key in params) {
    if (!!params[key]) {
      queryParams.append(key, params[key].toString());
    }
  }

  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
};
