import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { setMailingsMessage } from "../../redux/slices/mailings";
import {
  exportMessages,
  exportMessagesAsClsx,
  getOneMailings,
  startMailing,
} from "../../services/mailingsService";
import { Constant } from "../../template/Constant";
import { getByCompanyId } from "../../services/user_contactsService";
import { setCompaniesMessage } from "../../redux/slices/companies";
import { getByMailingId } from "../../services/sent_messagesService";
import {getBalance} from "../../services/balanceService";
import {setBalance} from "../../redux/slices/template";
import {useDispatch} from "react-redux";

type Props = {
  row?: any;
  handleListBtnClick: () => void;
};

export const MailingsShow: React.FC<Props> = ({ handleListBtnClick, row }) => {
  const [mailingRow, setMailingRow] = useState(null);
  const [messagesData, setMessagesData] = useState({} as any);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch()

  const getData = () => {
    getOneMailings(row.id).then((response) => {
      if (response) {
        setMailingRow(response.data);
      } else {
        setMailingsMessage({
          text: "No Record Found",
          type: Constant.alertVariantDanger,
        });
      }
    });

    getByMailingId(row.id, 1, Constant.defaultPageSize).then((response) => {
      if (response) {
        setMessagesData(response.data);
      } else {
        setCompaniesMessage({
          text: "No Record Found",
          type: Constant.alertVariantDanger,
        });
      }
    });
  };

  const search = () => {
    getByMailingId(row.id, 1, Constant.defaultPageSize, searchText).then(
      (response) => {
        if (response) {
          setMessagesData(response.data);
        } else {
          setCompaniesMessage({
            text: "No Record Found",
            type: Constant.alertVariantDanger,
          });
        }
      }
    );
  };

  const handleRowStart = () => {
    if (!mailingRow) return;

    startMailing(mailingRow.id).then((data: any) => {
      calcBalance()

      if (data.data) {
        return alert(data.data.message);
      }

      alert(data.message);
    });
  };

  const calcBalance = async () => {
    const data = await getBalance(
      JSON.parse(localStorage.getItem("userData")).id
    )

    dispatch(setBalance(data))
  }

  const downloadBlob = (blob: Blob, name = "export.csv") => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = name;
    a.click();
  };

  const handleExportMessages = () => {
    if (!mailingRow) return;

    exportMessages(mailingRow.id).then((data: any) => {
      const blob = new Blob([data.data]);

      downloadBlob(blob);
    });
  };

  const handleExportMessagesXlsx = () => {
    if (!mailingRow) return;

    exportMessagesAsClsx(mailingRow.id).then((data) => {
      const blob = new Blob([data.data]);
      downloadBlob(blob, "export.xlsx");
    });
  };

  const getMailingMessages = (perPage, page) => {
    getByMailingId(row.id, perPage, page).then((response) => {
      if (response) {
        setMessagesData(response.data);
      } else {
        setCompaniesMessage({
          text: "No Record Found",
          type: Constant.alertVariantDanger,
        });
      }
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getMailingMessages(page, newPerPage);
  };

  const handlePageChange = (page) => {
    getMailingMessages(page, messagesData.pageSize);
  };

  useEffect(() => {
    if (!mailingRow) {
      getData();
    }
  }, [mailingRow]);

  const columns = [
    { name: "id", selector: (row) => row.id, sortable: true },
    { name: "Номер", selector: (row) => row.phone, sortable: true },
    {
      name: "Тип сообщения ",
      selector: (row) => row.message_type,
      sortable: true,
    },
    {
      name: "Имя отправителя",
      selector: (row) => row.sender_name,
      sortable: false,
    },
    {
      name: "Статус",
      selector: (row) => {
        return (
          <h6>
            <Badge
              pill
              text="secondary"
              bg={row.badge_color}>
              {row.status_name}
            </Badge>
          </h6>
        );
      },
      sortable: false,
    },
    { name: "Цена", selector: (row) => row.message_cost, sortable: true },
    {
      name: "Текст",
      selector: (row) => row.message_text,
      sortable: true,
      minWidth: "400px"
    },
    {
      name: "Отправлено в",
      selector: (row) => {
        const date = Intl.DateTimeFormat("ru-RU", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(row.date_sent));

        return date !== "01.01.1970" ? date : null;
      },
      sortable: false,
      minWidth: "165px",
    },
  ];

  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Mailing #{row.id}
          <Button
            className="btn-icon-split float-right"
            onClick={handleListBtnClick}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Посмотреть рассылки</span>
          </Button>
        </h6>
      </Card.Header>
      {mailingRow ? (
        <Card.Body>
          <label className="form -control-label">Описание рассылки</label>
          <p>{mailingRow.description}</p>
          <label className="form -control-label">Cообщения</label>
          <Col
            className="ml-auto mb-3"
            md={3}
            xs={12}
            xl={3}>
            <div>
              <InputGroup>
                <Form.Control
                  placeholder="Поиск"
                  aria-label="Search"
                  aria-describedby="basic-search"
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button
                  disabled={searchText.length <= 2}
                  variant="dark"
                  className="rounded-0 rounded-right"
                  id="button-search"
                  onClick={search}>
                  Поиск
                </Button>
              </InputGroup>
            </div>
          </Col>
          <DataTable
            noDataComponent="Нет записей для отображения"
            columns={columns}
            paginationPerPage={Constant.defaultPageSize}
            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
            data={messagesData?.records || []}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={messagesData?.totalCount || 0}
            className="table table-bordered"
            pagination
            paginationServer
            customStyles={{
              cells: {
                style: {
                  padding: "8px 16px",
                  whiteSpace: "normal"
                },
                draggingStyle: {
                  whiteSpace: "normal"
                }
              }
            }}
            onChangePage={handlePageChange}></DataTable>
          <Button
            className="btn-icon-split float-right"
            onClick={handleRowStart}>
            <span className="text">Запустить рассылку</span>
          </Button>

          <Button
            variant="outline-dark"
            className="btn-icon-split float-right mr-3"
            onClick={handleExportMessagesXlsx}>
            <span className="text">Экспортировать сообщения в xlsx</span>
          </Button>
          <Button
            variant="dark"
            className="btn-icon-split float-right mr-3"
            onClick={handleExportMessages}>
            <span className="text">Экспортировать сообщения</span>
          </Button>
        </Card.Body>
      ) : null}
    </Card>
  );
};
