import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetOperatorsToInit, setOperatorsMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteOperators } from "services/operatorsService";
import Accordion from "react-bootstrap/Accordion";
import { genStringUUID } from "../../utils/genUUID";
import { useUser } from "hooks/useUser";

type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row) => void;
  getData: (page, pageSize, searchKey) => void;
};
export const OperatorsTable: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  getData,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const rData = useSelector((state: RootState) => state.operators);
  const [operators, setOperators] = useState([]);

  const { user } = useUser();

  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(page, newPerPage, "");
  };

  const handlePageChange = (page) => {
    getData(page, rData.pageSize, "");
  };

  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };

  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      dispatch(resetOperatorsToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    }
  }, [rData.list.length]);

  const handleReset = () => {
    setSearch("");
    dispatch(resetOperatorsToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };

  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteOperators(rowData.id);
      if (response) {
        dispatch(resetOperatorsToInit());
        dispatch(
          setOperatorsMessage({
            text: "Удалено успешно",
            type: Constant.alertVariantSuccess,
          })
        );
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        setShowDelete(false);
      } else {
        dispatch(
          setOperatorsMessage({
            text: "Ошибка!",
            type: Constant.alertVariantDanger,
          })
        );
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };
  const handleAddButtonClick = () => {
    dispatch(
      setOperatorsMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    hideShowForm("add");
  };

  useEffect(() => {
    setOperators(
      rData.list
        .filter((o) => o.parent_id === null)
        .map((o) => {
          return {
            id: o.id,
            operator_name: o.operator_name,
            is_default: o.is_default,
            is_moderated: o.is_moderated,
            parent_id: o.parent_id,
            childrens: rData.list.filter((_o) => _o.parent_id === o.id) || [],
          };
        })
    );

    console.log(operators);
  }, [rData]);

  const columns = [
    { name: "id", selector: (row) => row.id, sortable: true },
    {
      name: "operator_name",
      selector: (row) => row.operator_name,
      sortable: true,
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowEdit(row)}
        >
          Изменить
        </Button>
      ),
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowDeleteClick(row)}
        >
          Удалить
        </Button>
      ),
    },
  ];
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Список операторов ({rData.totalCount})
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}
          >
            <i className="fa fa-refresh"></i>
          </Button>
          {user.is_admin && (
            <Button
              className="btn-icon-split float-right"
              onClick={handleAddButtonClick}
            >
              <span className="icon text-white-50">
                <i className="fas fa-add"></i>
              </span>
              <span className="text">Добавить нового</span>
            </Button>
          )}
        </h6>
      </Card.Header>
      <Card.Body>
        <Col className="ml-auto" md={3} xs={12} xl={3}>
          <InputGroup>
            <Form.Control
              placeholder="Поиск"
              aria-label="Search"
              aria-describedby="basic-search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              disabled={search.length <= 2}
              variant="dark"
              className="rounded-0 rounded-right"
              id="button-search"
              onClick={handleSearch}
            >
              Поиск
            </Button>
          </InputGroup>
        </Col>
        <div className="table-responsive mt-3">
          {/*<DataTable*/}
          {/*  selectableRows={true}*/}
          {/*  onSelectedRowsChange={handleRowSelection}*/}
          {/*  paginationPerPage={Constant.defaultPageSize}*/}
          {/*  paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}*/}
          {/*  columns={columns}*/}
          {/*  data={rData.list}*/}
          {/*  onChangeRowsPerPage={handlePerRowsChange}*/}
          {/*  paginationTotalRows={rData.totalCount}*/}
          {/*  className="table table-bordered"*/}
          {/*  pagination*/}
          {/*  paginationServer*/}
          {/*  onChangePage={handlePageChange}*/}
          {/*></DataTable>*/}
          <Accordion
            defaultActiveKey="0"
            style={{ gap: "8px", display: "flex", flexDirection: "column" }}
          >
            {operators.map((o) => {
              const _operatorKey = genStringUUID();

              return (
                <Accordion>
                  <Accordion.Item eventKey={_operatorKey}>
                    <Accordion.Header>
                      <Button variant="info">
                        {o.operator_name} ({o.id})
                      </Button>
                      {user.is_admin ? (
                        <>
                          <Button
                            variant="link"
                            className="btn-sm"
                            onClick={() => handleRowEdit(o)}
                          >
                            Изменить
                          </Button>
                          <Button
                            variant="link"
                            className="btn-sm"
                            onClick={() => handleRowDeleteClick(o)}
                          >
                            Удалить
                          </Button>
                        </>
                      ) : null}
                    </Accordion.Header>
                    {user.is_admin ? (
                      <Accordion.Body>
                        <DataTable
                          noDataComponent="Нет записей для отображения"
                          columns={columns}
                          data={o.childrens}
                        ></DataTable>
                      </Accordion.Body>
                    ) : null}
                  </Accordion.Item>
                </Accordion>
              );
            })}
          </Accordion>{" "}
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative=" Отменить"
        buttonPositive="Удалить"
        title="Подтверждение удаления"
        show={showDelete}
        body={"Вы уверены?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
