import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form } from "react-bootstrap";
import {
  resetOperatorsToInit,
  resetUsersToInit,
  setOperatorsList,
  setSender_NamesMessage,
  setUsersList,
} from "redux/actions";

import { getUsers } from "services/usersService";
import { getModeratedOperators, getOperators } from "services/operatorsService";

import { useAppDispatch } from "redux/store";
import {
  addSender_Names,
  updateSender_Names,
} from "services/sender_namesService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { getSmsProviders } from "../../services/smsProviders.service";
import {
  resetSmsProvidersToInit,
  setSmsProvidersList,
  setSmsProvidersMessages,
} from "../../redux/slices/sms_providers";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const Sender_NamesForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    sender_name: "",
    users: [],
    operators: [],
    providers: [],
  };

  const initialValue = action === "edit" ? row : iValue;
  const usersData = useSelector((state: RootState) => state.users);
  const providerData = useSelector((state: RootState) => state.sms_providers);
  const operatorsData = useSelector((state: RootState) => state.operators);

  useEffect(() => {
    if (usersData && usersData.list && usersData.list.length === 0) {
      dispatch(resetUsersToInit());
      getUsers(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setUsersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "No Record Found For Users",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
    if (providerData && providerData.list && providerData.list.length === 0) {
      getSmsProviders(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        dispatch(resetSmsProvidersToInit());
        if (response && response.records) {
          dispatch(
            setSmsProvidersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSmsProvidersMessages({
              text: "No Record Found For Users",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
    if (
      operatorsData &&
      operatorsData.list &&
      operatorsData.list.length === 0
    ) {
      dispatch(resetOperatorsToInit());
      getOperators(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setOperatorsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records.filter((op) => !op.parent_id),
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "No Record Found For Operators",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
  }, []);
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateSender_Names(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setSender_NamesMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addSender_Names(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setSender_NamesMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      sender_name: yup.string().required("Введите имя"),
      users: yup
        .array()
        .required("users is required")
        .min(1, "Нужно выбрать хотя бы одного пользователя"),
      operators: yup
        .array()
        .required("operator_id is required")
        .min(1, "Нужно выбрать хотя бы 1 оператора"),
      providers: yup
        .array()
        .required("provider id is required")
        .min(1, "Нужно выбрать хотя бы 1 партнера"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {/* {action} Sender_Names */}
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Вернуться назад к именам отправителей</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Имя отправителя</label>
            <Form.Control
              type="text"
              name="sender_name"
              className="form-control"
              value={formik.values.sender_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.sender_name && !!formik.errors.sender_name
              }
              isValid={
                !!formik.touched.sender_name && !formik.errors.sender_name
              }></Form.Control>
            {formik.errors.sender_name && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.sender_name}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Имя пользователя</label>
            <Form.Control
              as="select"
              name="users"
              className="form-control mb-2"
              multiple
              value={formik.values.user_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.user_id && !!formik.errors.user_id}
              isValid={!!formik.touched.user_id && !formik.errors.user_id}>
              {usersData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`users-${i}`}>
                    {item.username}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.user_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.user_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Партнер для рассылок</label>
            <Form.Control
              as="select"
              multiple
              name="providers"
              className="form-control mb-2"
              value={formik.values.providers}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.providers && !!formik.errors.providers
              }
              isValid={!!formik.touched.providers && !formik.errors.providers}>
              {providerData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`providers-${i}`}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.providers && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.providers}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Оператор</label>
            <Form.Control
              as="select"
              multiple
              name="operators"
              className="form-control"
              value={formik.values.operators}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.operators && !!formik.errors.operators
              }
              isValid={!!formik.touched.operators && !formik.errors.operators}>
              {operatorsData.list
                .filter((op) => !op.parent_id)
                .map((item, i) => {
                  return (
                    <option
                      value={item.id}
                      key={`operators-${i}`}>
                      {item.operator_name}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.errors.operators && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.operators}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
