export {
  sidebarToggle,
  activateMenu,
  activateTopMenu,
} from "redux/slices/template";
export { setJWTToken, removeJWTToken } from "redux/slices/auth";

export {
  setUsersList,
  resetUsersToInit,
  setUsersMessage,
} from "redux/slices/users";

export {
  setUser_ContactsList,
  resetUser_ContactsToInit,
  setUser_ContactsMessage,
} from "redux/slices/user_contacts";

export {
  setTransactionsList,
  resetTransactionsToInit,
  setTransactionsMessage,
} from "redux/slices/transactions";

export {
  setStep_TariffsList,
  resetStep_TariffsToInit,
  setStep_TariffsMessage,
} from "redux/slices/step_tariffs";

export {
  setStep_DiscountsList,
  resetStep_DiscountsToInit,
  setStep_DiscountsMessage,
} from "redux/slices/step_discounts";

export {
  setSent_MessagesList,
  resetSent_MessagesToInit,
  setSent_MessagesMessage,
} from "redux/slices/sent_messages";

export {
  setSender_NamesList,
  resetSender_NamesToInit,
  setSender_NamesMessage,
} from "redux/slices/sender_names";

export {
  setRolesList,
  resetRolesToInit,
  setRolesMessage,
} from "redux/slices/roles";

export {
  setOperatorsList,
  resetOperatorsToInit,
  setOperatorsMessage,
} from "redux/slices/operators";

export {
  setMessage_TypesList,
  resetMessage_TypesToInit,
  setMessage_TypesMessage,
} from "redux/slices/message_types";

export {
  setMessage_TemplatesList,
  resetMessage_TemplatesToInit,
  setMessage_TemplatesMessage,
} from "redux/slices/message_templates";

export {
  setMessage_StatusList,
  resetMessage_StatusToInit,
  setMessage_StatusMessage,
} from "redux/slices/message_status";

export {
  setMailingsList,
  resetMailingsToInit,
  setMailingsMessage,
} from "redux/slices/mailings";

export {
  setCompaniesList,
  resetCompaniesToInit,
  setCompaniesMessage,
} from "redux/slices/companies";

export {
  setErrorCodeMessage,
  setErrorCodesList,
} from "redux/slices/error_reference";

export {
  setPercent,
} from "redux/slices/loading_file_progress"

export {
  setShortlinksList,
  reseSthortlinksInit,
  setShortlinksMessage,
} from "redux/slices/short_links";