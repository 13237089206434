import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IStep_Discounts {
  id: number;
  min_count?: number;
  max_count?: number;
  discount_percent?: number;
  operator_name?: string;
  provider_name?: string;
  type_name?: string;
}

interface IStep_DiscountsData {
  list?: Array<IStep_Discounts>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IStep_DiscountsData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "success" },
};

const step_discountsSlice = createSlice({
  name: "step_discounts",
  initialState,
  reducers: {
    setStep_DiscountsList: (
      state,
      _action: PayloadAction<IStep_DiscountsData>
    ) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetStep_DiscountsToInit: (state) => {
      state = initialState;
    },
    setStep_DiscountsMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const {
  setStep_DiscountsList,
  resetStep_DiscountsToInit,
  setStep_DiscountsMessage,
} = step_discountsSlice.actions;

export default step_discountsSlice.reducer;
