import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form } from "react-bootstrap";
import {
  resetUsersToInit,
  setTransactionsMessage,
  setUsersList,
} from "redux/actions";

import { getUsers } from "services/usersService";

import { useAppDispatch } from "redux/store";
import {
  addTransactions,
  updateTransactions,
} from "services/transactionsService";
import { Constant } from "template/Constant";
import * as yup from "yup";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const TransactionsForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = { user_id: 0, amount: 0 };
  const initialValue = action === "Редактирование" ? row : iValue;
  const usersData = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (usersData && usersData.list && usersData.list.length === 0) {
      dispatch(resetUsersToInit());
      getUsers(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setUsersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setTransactionsMessage({
              text: "Для пользователей не найдено ни одной записи",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
  }, []);
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "Редактирование") {
        const response = await updateTransactions(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setTransactionsMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setTransactionsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addTransactions(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setTransactionsMessage({
              text: "Добавлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setTransactionsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      amount: yup.number().nullable(),

      user_id: yup.string().required("user_id is required"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action == "add" ? "Добавление" : "Редактирование"} транзакции
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Вернуться к стоимости рассылки</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Сумма</label>
            <Form.Control
              type="number"
              name="amount"
              className="form-control"
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.amount && !!formik.errors.amount}
              isValid={
                !!formik.touched.amount && !formik.errors.amount
              }></Form.Control>
            {formik.errors.amount && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.amount}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">ID пользователя</label>
            <Form.Control
              as="select"
              name="user_id"
              className="form-control"
              value={formik.values.user_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.user_id && !!formik.errors.user_id}
              isValid={!!formik.touched.user_id && !formik.errors.user_id}>
              <option value={0}>Выберите пользователя</option>
              {usersData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`users-${i}`}>
                    {item.username}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.user_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.user_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
