export const USER_ROLES = {
  USER: "is_user",
  MODERATOR: "is_moderator",
  ADMIN: "is_admin",
} as const;

export type TUserRole = (typeof USER_ROLES)[keyof typeof USER_ROLES];

export const USER_ROLES_HIERARCHY = {
  [USER_ROLES.USER]: 1,
  [USER_ROLES.MODERATOR]: 0,
  [USER_ROLES.ADMIN]: 2,
} as const;

export const getUserHierarchy = (user: null | unknown) => {
  //@ts-ignore
  const { is_user, is_moderator, is_admin } = user ?? {};

  const userRoles = {
    is_user,
    is_moderator,
    is_admin,
  };

  const activeRole = Object.keys(userRoles).find((key) => !!userRoles[key]);

  if (!activeRole) return false;

  return USER_ROLES_HIERARCHY[activeRole as keyof typeof USER_ROLES] ?? 0;
};

const getMaxHierarchy = (roles: TUserRole[]) => {
  return Math.max(...roles.map((role) => USER_ROLES_HIERARCHY[role] ?? 0));
};

export const isRoutePermitted = (user, roles) => {
  const userHierarchy = getUserHierarchy(user);
  const maxHierarchy = getMaxHierarchy(roles);

  return userHierarchy >= maxHierarchy;
};
