import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IOperators {
  id: number;
  operator_name: string;
  is_default?: number;
  is_moderated?: number;
  parent_id?: number;
}

interface IOperatorsData {
  list?: Array<IOperators>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IOperatorsData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "" },
};

const operatorsSlice = createSlice({
  name: "operators",
  initialState,
  reducers: {
    setOperatorsList: (state, _action: PayloadAction<IOperatorsData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetOperatorsToInit: (state) => {
      Object.assign(state, initialState);
    },
    setOperatorsMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const { setOperatorsList, resetOperatorsToInit, setOperatorsMessage } =
  operatorsSlice.actions;

export default operatorsSlice.reducer;
