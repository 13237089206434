import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setMessage_TypesMessage } from "redux/actions";

import { useAppDispatch } from "redux/store";
import {
  addMessage_Types,
  updateMessage_Types,
} from "services/message_typesService";
import { Constant } from "template/Constant";
import * as yup from "yup";
type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const Message_TypesForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = { id: 0, type_name: "", char_limit: 0 };
  const initialValue = action === "edit" ? row : iValue;

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateMessage_Types(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setMessage_TypesMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setMessage_TypesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addMessage_Types(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setMessage_TypesMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setMessage_TypesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      type_name: yup.string().required("type_name это обязательное поле"),
      char_limit: yup.number().nullable(),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action == "add" ? "Добавление" : "Изменение"} Типа сообщений
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Назад к типам сообщений</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Название типа </label>
            <Form.Control
              type="text"
              name="type_name"
              className="form-control"
              value={formik.values.type_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.type_name && !!formik.errors.type_name
              }
              isValid={
                !!formik.touched.type_name && !formik.errors.type_name
              }></Form.Control>
            {formik.errors.type_name && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.type_name}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">
              Максимальное количество символов
            </label>
            <Form.Control
              type="number"
              name="char_limit"
              className="form-control"
              value={formik.values.char_limit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.char_limit && !!formik.errors.char_limit
              }
              isValid={
                !!formik.touched.char_limit && !formik.errors.char_limit
              }></Form.Control>
            {formik.errors.char_limit && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.char_limit}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
