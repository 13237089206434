import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PercentState {
  value: number
}

const initialState = { value: 0 } satisfies PercentState as PercentState


const loadingFileProgressSlice = createSlice({
  name: "loading_file_progress",
  initialState,
  reducers: {
    setPercent(state, action: PayloadAction<number>) {
      state.value = action.payload
    },
  },
});

export const { setPercent } =
loadingFileProgressSlice.actions;

export default loadingFileProgressSlice.reducer;
