import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth";
import { Dashboard } from "components/dashboard";
import { FileUpload } from "components/upload";
import { NotFound } from "./404";
import {
  ApiKeys,
  Companies,
  ErrorReference,
  Mailings,
  Message_Status,
  Message_Templates,
  Message_Types,
  Money_Transfer,
  Operators,
  Roles,
  Sender_Names,
  Sent_Messages,
  Step_Discounts,
  Step_Tariffs,
  Transactions,
  User_Contacts,
  Users,
  Series,
  Proxies,
  Short_Links
} from "components";
import { USER_ROLES } from "../consts/auth.consts";

const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route
          path="/dashboard"
          element={<AuthenticatedRoute element={<Dashboard />} />}
        ></Route>
        <Route
          path="/api_keys"
          element={<AuthenticatedRoute element={<ApiKeys />} />}
        ></Route>
        <Route
          path="/upload"
          element={<AuthenticatedRoute element={<FileUpload />} />}
        ></Route>
        <Route
          path="/users"
          element={
            <AuthenticatedRoute
              element={<Users />}
              roles={[USER_ROLES.ADMIN]}
            />
          }
        ></Route>
        <Route
          path="/user_contacts"
          element={<AuthenticatedRoute element={<User_Contacts />} />}
        ></Route>
        <Route
          path="/transactions"
          element={<AuthenticatedRoute element={<Transactions />} />}
        ></Route>
        <Route
          path="/error_reference"
          element={<AuthenticatedRoute element={<ErrorReference />} />}
        ></Route>

        <Route
          path="/step_tariffs"
          element={
            <AuthenticatedRoute
              element={<Step_Tariffs />}
              roles={[USER_ROLES.ADMIN]}
            />
          }
        ></Route>
        <Route
          path="/step_discounts"
          element={<AuthenticatedRoute element={<Step_Discounts />} />}
        ></Route>
        <Route
          path="/sent_messages"
          element={<AuthenticatedRoute element={<Sent_Messages />} />}
        ></Route>
        <Route
          path="/sender_names"
          element={<AuthenticatedRoute element={<Sender_Names />} />}
        ></Route>
        <Route
          path="/roles"
          element={<AuthenticatedRoute element={<Roles />} />}
        ></Route>
        <Route
          path="/series"
          element={<AuthenticatedRoute element={<Series />} />}
        ></Route>
        <Route
          path="/operators"
          element={<AuthenticatedRoute element={<Operators />} />}
        ></Route>
        <Route
          path="/message_types"
          element={<AuthenticatedRoute element={<Message_Types />} />}
        ></Route>
        <Route
          path="/message_templates"
          element={<AuthenticatedRoute element={<Message_Templates />} />}
        ></Route>
        <Route
          path="/message_status"
          element={<AuthenticatedRoute element={<Message_Status />} />}
        ></Route>
        <Route
          path="/mailings"
          element={<AuthenticatedRoute element={<Mailings />} />}
        ></Route>
        <Route
          path="/companies"
          element={<AuthenticatedRoute element={<Companies />} />}
        ></Route>
        <Route
          path="/money_transfer"
          element={<AuthenticatedRoute element={<Money_Transfer />} />}
        ></Route>
        <Route
          path="/proxies"
          element={<AuthenticatedRoute element={<Proxies />} />}
        ></Route>
        <Route
          path="/short_links"
          element={<AuthenticatedRoute element={<Short_Links />} />}
        ></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
