import React, {useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import {useSelector} from "react-redux";
import {RootState} from "redux/reducers";
import {useAppDispatch} from "redux/store";
import {
  resetMessage_TemplatesToInit,
  setMessage_TemplatesMessage,
} from "redux/actions";
import {Button, Card, Col, Form, InputGroup} from "react-bootstrap";
import {Constant} from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import {deleteMessage_Templates} from "services/message_templatesService";
import {useUser} from "../../hooks/useUser";

type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row) => void;
  getData: (page, pageSize, searchKey) => void;
};
export const Message_TemplatesTable: React.FC<Props> = ({
                                                          hideShowForm,
                                                          handleRowEdit,
                                                          getData,
                                                        }) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const rData = useSelector((state: RootState) => state.message_templates);
  const { user } = useUser()
  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(page, newPerPage, "");
  };
  const handlePageChange = (page) => {
    getData(page, rData.pageSize, "");
  };
  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };
  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      dispatch(resetMessage_TemplatesToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    }
  }, [rData.list.length]);
  const handleReset = () => {
    setSearch("");
    dispatch(resetMessage_TemplatesToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };
  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteMessage_Templates(rowData.id);
      if (response) {
        dispatch(resetMessage_TemplatesToInit());
        dispatch(
          setMessage_TemplatesMessage({
            text: "Удалено успешно",
            type: Constant.alertVariantSuccess,
          })
        );
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        setShowDelete(false);
      } else {
        dispatch(
          setMessage_TemplatesMessage({
            text: "Ошибка!",
            type: Constant.alertVariantDanger,
          })
        );
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };
  const handleAddButtonClick = () => {
    dispatch(
      setMessage_TemplatesMessage({
        text: "",
        type: Constant.alertVariantSuccess,
      })
    );
    hideShowForm("add");
  };

  const MyExpander = props => {
    return (
      <DataTable
        noDataComponent="Нет записей для отображения"
        columns={userColumns}
        data={props.data.users}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationTotalRows={props.data.users.length}
        className="table table-bordered"
        onChangePage={handlePageChange}></DataTable>
    )
  }

  let columns;
  let userColumns;

  if (user['is_admin'] === 1) {
    userColumns = [
      {name: "id", selector: (row) => row.id, sortable: true},
      {
        name: "Имя пользователя",
        selector: (row) => row.username,
        sortable: true,
      },
    ];

    columns = [
      {name: "id", selector: (row) => row.id, sortable: true},
      {
        name: "Текст сообщения",
        selector: (row) => row.template_text,
        sortable: true,
      },
      {
        name: "ID оператора",
        selector: (row) => row.operator_id,
        sortable: true,
      },
      {
        name: "Имя оператора",
        selector: (row) => row.operator_id_value,
        sortable: true,
      },

      {
        name: "Имя партнера",
        selector: (row) => row.message_type_id_value,
        sortable: true,
      },

      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowEdit(row)}>
            Изменить
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowDeleteClick(row)}>
            Удалить
          </Button>
        ),
      },
    ];
  } else {
    userColumns = [];

    columns = [
      {name: "id", selector: (row) => row.id, sortable: true},
      {
        name: "Текст сообщения",
        selector: (row) => row.template_text,
        sortable: true,
      },
      {
        name: "Имя оператора",
        selector: (row) => row.operator_id_value,
        sortable: true,
      }
    ];
  }

  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Список шаблонов ({rData.totalCount})
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}>
            <i className="fa fa-refresh"></i>
          </Button>
          {
            user['is_admin'] === 1 ? (
              <Button
                className="btn-icon-split float-right"
                onClick={handleAddButtonClick}>
                <span className="icon text-white-50">
                  <i className="fas fa-add"></i>
                </span>
                <span className="text">Добавить новый</span>
              </Button>
            ) : ""
          }
        </h6>
      </Card.Header>
      <Card.Body>
        <Col
          className="ml-auto"
          md={3}
          xs={12}
          xl={3}>
          <InputGroup>
            <Form.Control
              placeholder="Поиск"
              aria-label="Search"
              aria-describedby="basic-search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              disabled={search.length <= 2}
              variant="dark"
              className="rounded-0 rounded-right"
              id="button-search"
              onClick={handleSearch}>
              Поиск
            </Button>
          </InputGroup>
        </Col>
        <div className="table-responsive">
          <DataTable
            noDataComponent="Нет записей для отображения"
            selectableRows={true}
            expandableRows={user['is_admin'] === 1}
            expandableRowsComponent={MyExpander}
            onSelectedRowsChange={handleRowSelection}
            paginationPerPage={Constant.defaultPageSize}
            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
            columns={columns}
            data={rData.list}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={rData.totalCount}
            className="table table-bordered"
            pagination
            paginationServer
            onChangePage={handlePageChange}></DataTable>
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative=" Отменить"
        buttonPositive="Удалить"
        title="Подтверждение удаления"
        show={showDelete}
        body={"Вы уверены?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
