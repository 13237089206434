import { APIService } from "services";

export const getSender_Names = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllSender_Names(pageNo, pageSize);
  } else {
    try {
      res = await searchSender_Names(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};
export const getGroupedSenderNames = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllGroupedSenderNames(pageNo, pageSize);
  } else {
    try {
      res = await searchSender_Names(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const getSerieses = async () => {
  let res;
  res = await getAllSerieses();
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
}

export const addSender_Names = (data) => {
  return APIService.api().post(`/sender_names`, data);
};
export const updateSender_Names = (id, data) => {
  return APIService.api().patch(`/sender_names/${id}`, data);
};
export const addSeries = (data) => {
  return APIService.api().post(`/operators/series/create`, data);
};
export const updateSeries = (id, data) => {
  return APIService.api().patch(`/operators/series/${id}`, data);
};
export const getAllSender_Names = (pageNo, pageSize) => {
  return APIService.api().get(
    `/sender_names/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getAllSerieses = () => {
  return APIService.api().get(
      `/operators/series/get`
  );
}
export const getAllGroupedSenderNames = (pageNo, pageSize) => {
  return APIService.api().get(
    `/sender_names/group?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getOneSender_Names = (id) => {
  return APIService.api().get(`/sender_names/${id}`);
};
export const searchSender_Names = (searchKey, pageNo, pageSize) => {
  return APIService.api().get(
    `/sender_names/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const deleteSender_Names = (id) => {
  return APIService.api().delete(`/sender_names/${id}`);
};
