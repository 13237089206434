import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  setMessage_TemplatesList,
  setMessage_TemplatesMessage,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getMessage_Templates } from "services/message_templatesService";
import Layout from "template";
import { Constant } from "template/Constant";
import { Message_TemplatesForm } from "./form";
import { Message_TemplatesTable } from "./table";

export const Message_Templates: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.message_templates);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");
  const getData = (page, pageSize, searchKey) => {
    getMessage_Templates(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(
          setMessage_TemplatesList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.totalCount,
            searchKey: searchKey,
          })
        );
      } else {
        dispatch(
          setMessage_TemplatesMessage({
            text: "No Record Found",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });
  };

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(
      setMessage_TemplatesMessage({
        text: "",
        type: Constant.alertVariantSuccess,
      })
    );
    setAction("edit");
  };
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Шаблоны сообщений</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message.text ? (
            <Alert
              variant={rData.message.type}
              className="alert-dismissible fade">
              {rData.message.text}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() =>
                  dispatch(
                    setMessage_TemplatesMessage({
                      text: "",
                      type: Constant.alertVariantSuccess,
                    })
                  )
                }>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {action ? (
            <Message_TemplatesForm
              hideShowForm={setAction}
              action={action}
              row={row}
              getData={getData}
            />
          ) : (
            <Message_TemplatesTable
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
