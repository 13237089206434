import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetRolesToInit, setRolesMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteSeries } from "services/seriesService";

type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const SeriesTable: React.FC<Props> = ({
                                                hideShowForm,
                                                handleRowEdit,
                                                getData,
                                            }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.roles);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, "");
    };
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, "");
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    };
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetRolesToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        }
    }, [rData.list.length]);
    const handleReset = () => {
        setSearch("");
        dispatch(resetRolesToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, "");
    };
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteSeries(rowData.id);
            if (response) {
                dispatch(resetRolesToInit());
                dispatch(
                    setRolesMessage({
                        text: "Удалено успешно",
                        type: Constant.alertVariantSuccess,
                    })
                );
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
                setShowDelete(false);
            } else {
                dispatch(
                    setRolesMessage({
                        text: "Ошибка!",
                        type: Constant.alertVariantDanger,
                    })
                );
            }
        }
    };

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    };
    const handleAddButtonClick = () => {
        dispatch(setRolesMessage({ text: "", type: Constant.alertVariantSuccess }));
        hideShowForm("add");
    };

    const isAdmin = JSON.parse(localStorage.getItem('userData')).is_admin === 1;

    const columns = [
      { name: "id", selector: (row) => row.id, sortable: true },
      {
        name: "Операторы",
        selector: (row) => row.operator_names,
        sortable: true,
      },
      {
        name: "Имя отправителя",
        selector: (row) => row.sender_name,
        sortable: true,
      },
      { name: "Текст", selector: (row) => row.text, sortable: true },
      {
        name: "Пользователь",
        selector: (row) =>
          JSON.parse(localStorage.userData).is_admin === 1
            ? row.user_name
            : JSON.parse(localStorage.userData).username,
        sortable: true,
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowEdit(row)}
          >
            Изменить
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowDeleteClick(row)}
          >
            Удалить
          </Button>
        ),
      },
    ];
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                    Список серий ({Object.keys(rData.list.filter((name) =>
                    // @ts-ignore
                    {
                        if(JSON.parse(localStorage.userData).is_admin === 1) {
                            return true;
                        } else {
                            // @ts-ignore
                            return ((name.user_id).split(",").map(Number)).indexOf(JSON.parse(localStorage.userData).id) >= 0
                        }
                    }
                )).length})
                    <Button
                        variant="light"
                        className="btn-circle btn-sm ml-2"
                        onClick={handleReset}>
                        <i className="fa fa-refresh"></i>
                    </Button>
                    <Button
                        className="btn-icon-split float-right"
                        onClick={handleAddButtonClick}>
            <span className="icon text-white-50">
              <i className="fas fa-add"></i>
            </span>
                        <span className="text">Добавить новую</span>
                    </Button>
                </h6>
            </Card.Header>
            <Card.Body>
                <Col
                    className="ml-auto"
                    md={3}
                    xs={12}
                    xl={3}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Поиск"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button
                            disabled={search.length <= 2}
                            variant="dark"
                            className="rounded-0 rounded-right"
                            id="button-search"
                            onClick={handleSearch}>
                            Поиск
                        </Button>
                    </InputGroup>
                </Col>
                <div className="table-responsive">
                    <DataTable
                        noDataComponent="Нет записей для отображения"
                        selectableRows={true}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageSize}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns}
                        data={rData.list.filter((name) => {
                            if(JSON.parse(localStorage.userData).is_admin === 1) {
                                return true;
                            } else {
                                // @ts-ignore
                                return ((name.user_id).split(",").map(Number)).indexOf(JSON.parse(localStorage.userData).id) >= 0
                            }
                        })}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        onChangePage={handlePageChange}></DataTable>
                </div>
            </Card.Body>
            <ConfirmationModal
                buttonNegative=" Отменить"
                buttonPositive="Удалить"
                title="Подтверждение удаления"
                show={showDelete}
                body={"Вы уверены?"}
                onNegative={() => setShowDelete(false)}
                onPositive={handleServerDelete}
            />
        </Card>
    );
};
