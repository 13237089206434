import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetCompaniesToInit, setCompaniesMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteCompanies } from "services/companiesService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useUser } from "../../hooks/useUser";

type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row) => void;
  handleRowShow: (row) => void;
  getData: (page, pageSize, searchKey) => void;
  getDataDate: (page, pageSize, dates) => void;
};
export const CompaniesTable: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  handleRowShow,
  getData,
  getDataDate,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const rData = useSelector((state: RootState) => state.companies);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(page, newPerPage, "");
  };

  const handlePageChange = (page) => {
    getData(page, rData.pageSize, "");
  };
  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };

  let try_reget = false;

  useEffect(() => {
    if (rData && rData.list && !try_reget) {
      try_reget = true;
      dispatch(resetCompaniesToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    }
  }, []);

  const handleReset = () => {
    setSearch("");
    dispatch(resetCompaniesToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };

  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteCompanies(rowData.id);
      if (response) {
        dispatch(resetCompaniesToInit());
        dispatch(
          setCompaniesMessage({
            text: "Удалено успешно",
            type: Constant.alertVariantSuccess,
          })
        );
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        setShowDelete(false);
      } else {
        dispatch(
          setCompaniesMessage({
            text: "Ошибка!",
            type: Constant.alertVariantDanger,
          })
        );
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };

  const handleAddButtonClick = () => {
    dispatch(
      setCompaniesMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    hideShowForm("add");
  };

  const handleSelect = (date) => {
    setDateRange(date);
    if (date[1] !== null) {
      getDataDate(Constant.defaultPageNumber, Constant.defaultPageSize, date);
    }
  };

  const { user } = useUser();

  let columns;

  if (user.is_admin === 1) {
    columns = [
      { name: "id", selector: (row) => row.id, sortable: true },
      {
        name: "Пользователь",
        selector: (row) => row.username,
        sortable: true,
      },
      {
        name: "Название компании",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Прогресс",
        selector: (row) => `${row.import_progress}%`,
        sortable: true,
      },
      {
        name: "Загруенно строк",
        selector: (row) => row.success_rows,
        sortable: true,
      },
      {
        name: "Строк с ошибкой",
        selector: (row) => row.error_rows,
        sortable: true,
      },
      {
        name: "Всего строк",
        selector: (row) => row.total_rows,
        sortable: true,
      },
      {
        name: "Дата создания",
        selector: (row) =>
          Intl.DateTimeFormat("ru-RU", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date(row.date_created)),
        sortable: true,
        minWidth: "165px",
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowShow(row)}>
            Посмотреть
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowEdit(row)}>
            Изменить
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowDeleteClick(row)}>
            Удалить
          </Button>
        ),
      },
    ];
  } else {
    columns = [
      { name: "id", selector: (row) => row.id, sortable: true },
      {
        name: "Название компании",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Прогресс",
        selector: (row) => `${row.import_progress}%`,
        sortable: true,
      },
      {
        name: "Загруенно строк",
        selector: (row) => row.success_rows,
        sortable: true,
      },
      {
        name: "Строк с ошибкой",
        selector: (row) => row.error_rows,
        sortable: true,
      },
      {
        name: "Всего строк",
        selector: (row) => row.total_rows,
        sortable: true,
      },
      {
        name: "Дата создания",
        selector: (row) =>
          Intl.DateTimeFormat("ru-RU", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date(row.date_created)),
        sortable: true,
        minWidth: "165px",
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowShow(row)}>
            Show
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowEdit(row)}>
            Изменить
          </Button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <Button
            variant="link"
            className="btn-sm"
            onClick={() => handleRowDeleteClick(row)}>
            Удалить
          </Button>
        ),
      },
    ];
  }
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Список ({rData.totalCount})
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}>
            <i className="fa fa-refresh"></i>
          </Button>
          <Button
            className="btn-icon-split float-right"
            onClick={handleAddButtonClick}>
            <span className="icon text-white-50">
              <i className="fas fa-add"></i>
            </span>
            <span className="text">Добавить новую</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col
            className="ml-auto"
            md={3}
            xs={12}
            xl={3}>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                handleSelect(update);
              }}
              isClearable={true}
            />
          </Col>
          <Col
            className="ml-auto"
            md={3}
            xs={12}
            xl={3}>
            <InputGroup>
              <Form.Control
                placeholder="Поиск"
                aria-label="Search"
                aria-describedby="basic-search"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                disabled={search.length <= 2}
                variant="dark"
                className="rounded-0 rounded-right"
                id="button-search"
                onClick={handleSearch}>
                Поиск
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <div className="table-responsive">
          <DataTable
            selectableRows={true}
            onSelectedRowsChange={handleRowSelection}
            paginationPerPage={Constant.defaultPageSize}
            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
            columns={columns}
            data={rData.list}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={rData.totalCount}
            className="table table-bordered"
            pagination
            paginationServer
            onChangePage={handlePageChange}></DataTable>
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative=" Отменить"
        buttonPositive="Удалить"
        title="Подтверждение удаления"
        show={showDelete}
        body={"Вы уверены?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
