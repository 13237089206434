import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetUsersToInit, setUsersMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteUsers } from "services/usersService";

type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: () => void;
  getData: (page, pageSize, searchKey) => void;
};
export const UsersTable: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  getData,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const rData = useSelector((state: RootState) => state.users);
  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(page, newPerPage, "");
  };
  const handlePageChange = (page) => {
    getData(page, rData.pageSize, "");
  };
  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };
  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      dispatch(resetUsersToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    }
  }, [rData.list.length]);
  const handleReset = () => {
    setSearch("");
    dispatch(resetUsersToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };
  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteUsers(rowData.id);
      if (response) {
        dispatch(resetUsersToInit());
        dispatch(
          setUsersMessage({
            text: "Удалено успешно",
            type: Constant.alertVariantSuccess,
          })
        );
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        setShowDelete(false);
      } else {
        dispatch(
          setUsersMessage({
            text: "Ошибка!",
            type: Constant.alertVariantDanger,
          })
        );
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };
  const handleAddButtonClick = () => {
    dispatch(setUsersMessage({ text: "", type: Constant.alertVariantSuccess }));
    hideShowForm("add");
  };

  const columns = [
    { name: "id", selector: (row) => row.id, sortable: true },
    { name: "ID пользователя", selector: (row) => row.user_id, sortable: true },
    {
      name: "Имя пользователя",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Баланс пользователя",
      selector: (row) => row.balance,
      sortable: true,
    },
    { name: "Сумма до", selector: (row) => row.oldSumm, sortable: true },
    { name: "Сумма после", selector: (row) => row.newSumm, sortable: true },
  ];
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Денежные движения ({rData.totalCount})
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}
          >
            <i className="fa fa-refresh"></i>
          </Button>
          <Button
            className="btn-icon-split float-right"
            onClick={handleAddButtonClick}
          >
            <span className="icon text-white-50">
              <i className="fas fa-add"></i>
            </span>
            <span onClick={() => handleRowEdit()} className="text">
              Добавить
            </span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Col className="ml-auto" md={3} xs={12} xl={3}>
          <InputGroup>
            <Form.Control
              placeholder="Поиск"
              aria-label="Search"
              aria-describedby="basic-search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              disabled={search.length <= 2}
              variant="dark"
              className="rounded-0 rounded-right"
              id="button-search"
              onClick={handleSearch}
            >
              Поиск
            </Button>
          </InputGroup>
        </Col>
        <div className="table-responsive">
          <DataTable
            noDataComponent="Нет записей для отображения"
            selectableRows={true}
            onSelectedRowsChange={handleRowSelection}
            paginationPerPage={Constant.defaultPageSize}
            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
            columns={columns}
            data={rData.list}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={rData.totalCount}
            className="table table-bordered"
            pagination
            paginationServer
            onChangePage={handlePageChange}
          ></DataTable>
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative=" Отменить"
        buttonPositive="Удалить"
        title="Подтверждение удаления"
        show={showDelete}
        body={"Вы уверены?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
