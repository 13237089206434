import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form } from "react-bootstrap";
import { resetRolesToInit, setRolesList, setUsersMessage } from "redux/actions";

import { getRoles } from "services/rolesService";

import { useAppDispatch } from "redux/store";
import { addUsers, updateUsers } from "services/usersService";
import { Constant } from "template/Constant";
import * as yup from "yup";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const UsersForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    username: "",
    password_hash: "",
    role_id: 0,

    additional_discount: 0,
  };
  const initialValue = action === "edit" ? row : iValue;
  const rolesData = useSelector((state: RootState) => state.roles);

  useEffect(() => {
    if (rolesData && rolesData.list && rolesData.list.length === 0) {
      dispatch(resetRolesToInit());
      getRoles(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setRolesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setUsersMessage({
              text: "No Record Found For Roles",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateUsers(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setUsersMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setUsersMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addUsers(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setUsersMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setUsersMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      username: yup.string().required("Поле обязательно для заполнения"),
      password_hash: yup.string().required("Поле обязательно для заполнения"),

      additional_discount: yup.number().nullable(),
      role_id: yup.string().required("Поле обязательно для заполнения"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "add" ? "Добавление" : "Измение"} пользователя
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Просмотр пользователей</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Имя пользователя</label>
            <Form.Control
              type="text"
              name="username"
              className="form-control"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.username && !!formik.errors.username}
              isValid={
                !!formik.touched.username && !formik.errors.username
              }></Form.Control>
            {formik.errors.username && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.username}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Пароль</label>
            <Form.Control
              type="text"
              name="password_hash"
              className="form-control"
              value={formik.values.password_hash}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.password_hash && !!formik.errors.password_hash
              }
              isValid={
                !!formik.touched.password_hash && !formik.errors.password_hash
              }></Form.Control>
            {formik.errors.password_hash && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.password_hash}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">
              Скидка от базового прайса
            </label>
            <Form.Control
              type="number"
              name="additional_discount"
              className="form-control"
              value={formik.values.additional_discount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.additional_discount &&
                !!formik.errors.additional_discount
              }
              isValid={
                !!formik.touched.additional_discount &&
                !formik.errors.additional_discount
              }></Form.Control>
            {formik.errors.additional_discount && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.additional_discount}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Роль</label>
            <Form.Control
              as="select"
              name="role_id"
              className="form-control"
              value={formik.values.role_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.role_id && !!formik.errors.role_id}
              isValid={!!formik.touched.role_id && !formik.errors.role_id}>
              <option value={0}>Выберите Роль</option>
              {rolesData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`roles-${i}`}>
                    {item.role_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.role_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.role_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
