import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import {
  resetSent_MessagesToInit,
  setMessage_TypesList,
  setSent_MessagesMessage,
} from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteSent_Messages } from "services/sent_messagesService";
import { getMessage_Types } from "../../services/message_typesService";
import { ISent_Messages } from "../../redux/slices/sent_messages";

type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row) => void;
  getData: (page, pageSize, searchKey, type?: string | number) => void;
  setMessageType: (type: number | null) => void;
  messageType: number | null;
};
export const Sent_MessagesTable: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  getData,
  setMessageType,
  messageType,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const rData = useSelector((state: RootState) => state.sent_messages);
  const messageTypes = useSelector((state: RootState) => state.message_types);

  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(page, newPerPage, "");
  };
  const handlePageChange = (page) => {
    getData(page, rData.pageSize, "");
  };
  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };

  useEffect(() => {
    getData(
      Constant.defaultPageNumber,
      Constant.defaultPageSize,
      "",
      messageType
    );

    getMessage_Types(
      Constant.defaultPageNumber,
      Constant.defaultDropdownPageSize,
      ""
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setMessage_TypesList({
            pageNo: Constant.defaultPageNumber,
            pageSize: Constant.defaultDropdownPageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: "",
          })
        );
      } else {
        dispatch(
          setSent_MessagesMessage({
            text: "No Record Found For Message_Types",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });
  }, [messageType]);

  const handleReset = () => {
    setSearch("");
    dispatch(resetSent_MessagesToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };
  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteSent_Messages(rowData.id);
      if (response) {
        dispatch(resetSent_MessagesToInit());
        dispatch(
          setSent_MessagesMessage({
            text: "Удалено успешно",
            type: Constant.alertVariantSuccess,
          })
        );
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        setShowDelete(false);
      } else {
        dispatch(
          setSent_MessagesMessage({
            text: "Ошибка!",
            type: Constant.alertVariantDanger,
          })
        );
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };
  const handleAddButtonClick = () => {
    dispatch(
      setSent_MessagesMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    hideShowForm("add");
  };

  const columns: TableColumn<ISent_Messages>[] = [
    { name: "id", selector: (row) => row.id, sortable: true },

    {
      name: "Номер",
      selector: (row) => row.user_contact,
      sortable: true,
    },
    {
      name: "Текст сообщения",
      selector: (row) => row.message_template,
      sortable: true,
    },

    {
      name: "Тип сообщения",
      selector: (row) => row.message_type,
      sortable: true,
    },

    {
      name: "Имя отправителя",
      selector: (row) => row.sender_name,
      sortable: true,
    },

    {
      name: "Статус сообщения",
      selector: (row) => row.message_status,
      sortable: true,
    },

    {
      name: "Дата создания",
      selector: (row) =>
        Intl.DateTimeFormat("ru-RU", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(row.date_added_to_tasks)),
      sortable: true,
      minWidth: "165px",
    },
    {
      name: "Дата отправки",
      selector: (row) =>
        Intl.DateTimeFormat("ru-RU", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }).format(new Date(row.date_sent)),
      sortable: true,
      minWidth: "165px",
    },

    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowEdit(row)}>
          Изменить
        </Button>
      ),
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowDeleteClick(row)}>
          Удалить
        </Button>
      ),
    },
  ];
  // @ts-ignore
  // @ts-ignore
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Список отправленных сообщений ({rData.totalCount})
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}>
            <i className="fa fa-refresh"></i>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <div
          className="d-flex w-100 align-items-xl-center  pb-1 flex-xl-row flex-column align-items-baseline "
          style={{ justifyContent: "space-between" }}>
          <Col
            md={12}
            xl={3}
            className="mb-2 mb-xl-0">
            <Form.Group>
              <Form.Control
                /* @ts-ignore */
                placeholder="Выберите тип сообщения"
                as="select"
                name="message_type_id"
                className="form-control"
                onChange={(ev) => {
                  const { value } = ev.target;
                  const nextMessageType = value ? +value : 0;
                  setMessageType(nextMessageType);
                }}>
                <option value={0}>Все типы</option>
                {messageTypes.list.map((item, i) => {
                  return (
                    <option
                      value={item.id}
                      key={`message_types-${i}`}>
                      {item.type_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col
            md={12}
            xl={3}>
            <InputGroup>
              <Form.Control
                placeholder="Поиск"
                aria-label="Search"
                aria-describedby="basic-search"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                disabled={search.length <= 2}
                variant="dark"
                className="rounded-0 rounded-right"
                id="button-search"
                onClick={handleSearch}>
                Поиск
              </Button>
            </InputGroup>
          </Col>
        </div>

        <div className="table-responsive">
          <DataTable
            noDataComponent="Нет записей для отображения"
            selectableRows={true}
            onSelectedRowsChange={handleRowSelection}
            paginationPerPage={Constant.defaultPageSize}
            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
            columns={columns}
            data={rData.list}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={rData.totalCount}
            className="table table-bordered"
            pagination
            paginationServer
            onChangePage={handlePageChange}></DataTable>
        </div>
      </Card.Body>
      <ConfirmationModal
        buttonNegative=" Отменить"
        buttonPositive="Удалить"
        title="Подтверждение удаления"
        show={showDelete}
        body={"Вы уверены?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
