import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRShortlink {
  id: number;
  shortlink: string;
  users: string;
}

interface IRShortlinksData {
  list?: Array<IRShortlink>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IRShortlinksData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "success" },
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setShortlinksList: (state, _action: PayloadAction<IRShortlinksData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    reseSthortlinksInit: (state) => {
      state = initialState;
    },
    setShortlinksMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const { setShortlinksList, reseSthortlinksInit, setShortlinksMessage } =
  rolesSlice.actions;

export default rolesSlice.reducer;
