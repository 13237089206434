import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  resetCompaniesToInit,
  setCompaniesList,
  setCompaniesMessage,
  setMailingsMessage,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getCompanies, getCompaniesDates } from "services/companiesService";
import Layout from "template";
import { Constant } from "template/Constant";
import { CompaniesForm } from "./form";
import { CompaniesTable } from "./table";
import { CompaniesShow } from "./show";
import { constants } from "buffer";

export const Companies: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.companies);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");

  const getData = (page, pageSize, searchKey) => {
    getCompanies(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(
          setCompaniesList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.totalCount,
            searchKey: searchKey,
          })
        );
      } else {
        dispatch(
          setCompaniesMessage({
            text: "No Record Found",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });
  };

  const getByDate = (page, pageSize, dates) => {
    getCompaniesDates(page, pageSize, dates).then((response) => {
      dispatch(
        setCompaniesMessage({ text: "", type: Constant.alertVariantSuccess })
      );
      dispatch(resetCompaniesToInit());

      if (response && response.records.length > 0) {
        dispatch(
          setCompaniesList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.totalCount,
          })
        );
      } else {
        dispatch(
          setCompaniesMessage({
            text: "No Record Found",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });

    console.log(dates);
  };

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(
      setCompaniesMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    setAction("edit");
  };

  const handleRowShow = (rowData) => {
    setRow(rowData);
    dispatch(
      setCompaniesMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    setAction("show");
  };

  const handleListBtnClick = () => {
    dispatch(
      setCompaniesMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    setAction("");
  };

  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Рекламные компании</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message?.text ? (
            <Alert
              variant={rData.message.type}
              className="alert-dismissible fade">
              {rData.message.text}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() =>
                  dispatch(
                    setCompaniesMessage({
                      text: "",
                      type: Constant.alertVariantSuccess,
                    })
                  )
                }>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {!action ? (
            <CompaniesTable
              handleRowShow={handleRowShow}
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
              getDataDate={getByDate}
            />
          ) : null}
          {action === "add" || action === "edit" ? (
            <CompaniesForm
              hideShowForm={setAction}
              action={action}
              row={row}
              getData={getData}
            />
          ) : null}
          {action === "show" ? (
            <CompaniesShow
              handleListBtnClick={handleListBtnClick}
              row={row}
            />
          ) : null}
        </div>
      </div>
    </Layout>
  );
};
