import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IStep_Tariffs {
  id: number;
  operator_id?: number;
  message_type_id?: number;
  min_count?: number;
  max_count?: number;
  cost_per_message?: number;
  operator_name?: string;
  provider_name?: string;
  type_name?: string;
}

interface IStep_TariffsData {
  list?: Array<IStep_Tariffs>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IStep_TariffsData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "success" },
};

const step_tariffsSlice = createSlice({
  name: "step_tariffs",
  initialState,
  reducers: {
    setStep_TariffsList: (state, _action: PayloadAction<IStep_TariffsData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetStep_TariffsToInit: (state) => {
      state = initialState;
    },
    setStep_TariffsMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const {
  setStep_TariffsList,
  resetStep_TariffsToInit,
  setStep_TariffsMessage,
} = step_tariffsSlice.actions;

export default step_tariffsSlice.reducer;
