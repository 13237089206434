import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IMessage_Types {
  id: number;
  type_name: string;
  char_limit?: number;
}

interface IMessage_TypesData {
  list?: Array<IMessage_Types>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IMessage_TypesData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "success" },
};

const message_typesSlice = createSlice({
  name: "message_types",
  initialState,
  reducers: {
    setMessage_TypesList: (
      state,
      _action: PayloadAction<IMessage_TypesData>
    ) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetMessage_TypesToInit: (state) => {
      state = initialState;
    },
    setMessage_TypesMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const {
  setMessage_TypesList,
  resetMessage_TypesToInit,
  setMessage_TypesMessage,
} = message_typesSlice.actions;

export default message_typesSlice.reducer;
