import { useFormik } from "formik";
import { useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setErrorCodeMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import {
  resetSmsProvidersToInit,
  setSmsProvidersList,
  setSmsProvidersMessages,
} from "redux/slices/sms_providers";
import { useAppDispatch } from "redux/store";
import { createErrorCode, updateErrorCode } from "services/errorService";
import { getSmsProviders } from "services/smsProviders.service";
import { Constant } from "template/Constant";
import * as yup from "yup";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const ErrorForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const providerData = useSelector((state: RootState) => state.sms_providers);
  const iValue = {
    id: 0,
    sms_provider_id: providerData[0]?.id || 1,
    internal_code: "",
    local_code: "",
    message: "",
  };
  const initialValue = action === "edit" ? row : iValue;

  console.log(initialValue);
  useEffect(() => {
    if (providerData && providerData.list && providerData.list.length === 0) {
      getSmsProviders(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        dispatch(resetSmsProvidersToInit());
        if (response && response.records) {
          dispatch(
            setSmsProvidersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSmsProvidersMessages({
              text: "No Record Found For Users",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateErrorCode(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setErrorCodeMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setErrorCodeMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await createErrorCode(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setErrorCodeMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setErrorCodeMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      sms_provider_id: yup
        .number()
        .moreThan(0, "Поле обязательно для заполнения")
        .required("Поле обязательно для заполнения"),
      local_code: yup.string().required("Поле обязательно для заполнения"),
      internal_code: yup.string().required("Поле обязательно для заполнения"),
      message: yup.string().required("Поле обязательно для заполнения"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "add" ? "Добавление" : "Измение"} кода ошибки
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Просмотр кодов ошибок</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Поставщик</label>
            <Form.Control
              as="select"
              name="sms_provider_id"
              className="form-control"
              value={formik.values.sms_provider_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                // !!formik.touched.sms_provider_id &&
                !!formik.errors.sms_provider_id
              }
              isValid={
                // !!formik.touched.sms_provider_id &&
                !formik.errors.sms_provider_id
              }>
              {providerData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`providers-${i}`}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.sms_provider_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.sms_provider_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Локальный код</label>
            <Form.Control
              type="text"
              name="local_code"
              className="form-control"
              value={formik.values.local_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.local_code && !!formik.errors.local_code
              }
              isValid={
                !!formik.touched.local_code && !formik.errors.local_code
              }></Form.Control>
            {formik.errors.local_code && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.local_code}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Код ошибки у партнера</label>
            <Form.Control
              type="text"
              name="internal_code"
              className="form-control"
              value={formik.values.internal_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.internal_code && !!formik.errors.internal_code
              }
              isValid={
                !!formik.touched.internal_code && !formik.errors.internal_code
              }></Form.Control>
            {formik.errors.internal_code && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.internal_code}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">Сообщение</label>
            <Form.Control
              type="text"
              name="message"
              className="form-control"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.message && !!formik.errors.message}
              isValid={
                !!formik.touched.message && !formik.errors.message
              }></Form.Control>
            {formik.errors.message && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.message}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
