import { APIService } from "services";

export const getShortLinks = async (pageNo, pageSize) => {
  let res;
  res = await getAllShortlinks(pageNo, pageSize);
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const getAllShortlinks = (pageNo, pageSize) => {
  return APIService.api().get(`/shortlinks/`);
};
export const getShortlinksByUser = () => {
  return APIService.api().get(`/shortlinks/byuser/`);
};

export const addShort_links = (data) => {
  return APIService.api().post(`/shortlinks`, data);
};
export const updateShort_links = (id, data) => {
  return APIService.api().patch(`/shortlinks/${id}`, data);
};

export const deleteShortlink = (id) => {
  return APIService.api().delete(`/shortlinks/${id}`);
};
