import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import Layout from "template";
import { Constant } from "template/Constant";
import { ErrorForm } from "./form";
import { ErrorTable } from "./table";
import { getErrorCodes } from "services/errorService";
import {
  setErrorCodeMessage,
  setErrorCodesList,
} from "redux/slices/error_reference";

export const ErrorReference: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.errorCodes);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");
  const getData = (page, pageSize) => {
    getErrorCodes(page, pageSize).then((response) => {
      console.log(response && response);
      if (response.data && response.data.records) {
        dispatch(
          setErrorCodesList({
            pageNo: page,
            pageSize: pageSize,
            list: response.data.records,
            totalCount: response.data.totalCount,
          })
        );
      } else {
        dispatch(
          setErrorCodeMessage({
            text: "Записи не найдены",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });
  };

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(
      setErrorCodeMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    setAction("edit");
  };
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Справочник ошибок</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message.text ? (
            <Alert
              variant={rData.message.type}
              className="alert-dismissible fade">
              {rData.message.text}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() =>
                  dispatch(
                    setErrorCodeMessage({
                      text: "",
                      type: Constant.alertVariantSuccess,
                    })
                  )
                }>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {action ? (
            <ErrorForm
              hideShowForm={setAction}
              action={action}
              row={row}
              getData={getData}
            />
          ) : (
            <ErrorTable
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
