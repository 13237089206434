export const Constant = {
  defaultPageSize: 20,
  defaultPageNumber: 1,
  defaultDropdownPageSize: 500,
  paginationRowsPerPageOptions: [5, 10, 20, 40],
  alertVariantSuccess: "success",
  alertVariantWarning: "warning",
  alertVariantDanger: "danger",

  defaultAlertShowTime: 7000,
};
