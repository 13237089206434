import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form } from "react-bootstrap";
import {
  setMessage_StatusList,
  setMessage_TemplatesList,
  setMessage_TypesList,
  setSender_NamesList,
  setSent_MessagesMessage,
  setUser_ContactsList,
} from "redux/actions";

import { getUser_Contacts } from "services/user_contactsService";
import { getMessage_Templates } from "services/message_templatesService";
import { getMessage_Types } from "services/message_typesService";
import { getSender_Names } from "services/sender_namesService";
import { getMessage_Status } from "services/message_statusService";

import { useAppDispatch } from "redux/store";
import {
  addSent_Messages,
  updateSent_Messages,
} from "services/sent_messagesService";
import { Constant } from "template/Constant";
import * as yup from "yup";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const Sent_MessagesForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    user_contact_id: 0,
    message_template_id: 0,
    message_type_id: 0,
    sender_name_id: 0,
    message_status_id: 0,
  };
  const initialValue = action === "edit" ? row : iValue;
  const user_contactsData = useSelector(
    (state: RootState) => state.user_contacts
  );
  const message_templatesData = useSelector(
    (state: RootState) => state.message_templates
  );
  const message_typesData = useSelector(
    (state: RootState) => state.message_types
  );
  const sender_namesData = useSelector(
    (state: RootState) => state.sender_names
  );
  const message_statusData = useSelector(
    (state: RootState) => state.message_status
  );

  useEffect(() => {
    getUser_Contacts(
      Constant.defaultPageNumber,
      Constant.defaultDropdownPageSize,
      ""
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setUser_ContactsList({
            pageNo: Constant.defaultPageNumber,
            pageSize: Constant.defaultDropdownPageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: "",
          })
        );
      } else {
        dispatch(
          setSent_MessagesMessage({
            text: "No Record Found For User_Contacts",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });

    getMessage_Templates(
      Constant.defaultPageNumber,
      Constant.defaultDropdownPageSize,
      ""
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setMessage_TemplatesList({
            pageNo: Constant.defaultPageNumber,
            pageSize: Constant.defaultDropdownPageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: "",
          })
        );
      } else {
        dispatch(
          setSent_MessagesMessage({
            text: "No Record Found For Message_Templates",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });

    getMessage_Types(
      Constant.defaultPageNumber,
      Constant.defaultDropdownPageSize,
      ""
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setMessage_TypesList({
            pageNo: Constant.defaultPageNumber,
            pageSize: Constant.defaultDropdownPageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: "",
          })
        );
      } else {
        dispatch(
          setSent_MessagesMessage({
            text: "No Record Found For Message_Types",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });

    getSender_Names(
      Constant.defaultPageNumber,
      Constant.defaultDropdownPageSize,
      ""
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setSender_NamesList({
            pageNo: Constant.defaultPageNumber,
            pageSize: Constant.defaultDropdownPageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: "",
          })
        );
      } else {
        dispatch(
          setSent_MessagesMessage({
            text: "No Record Found For Sender_Names",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });

    getMessage_Status(
      Constant.defaultPageNumber,
      Constant.defaultDropdownPageSize,
      ""
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setMessage_StatusList({
            pageNo: Constant.defaultPageNumber,
            pageSize: Constant.defaultDropdownPageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: "",
          })
        );
      } else {
        dispatch(
          setSent_MessagesMessage({
            text: "No Record Found For Message_Status",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateSent_Messages(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setSent_MessagesMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setSent_MessagesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addSent_Messages(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setSent_MessagesMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setSent_MessagesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      user_contact_id: yup
        .string()
        .required("user_contact_id это обязательное поле"),
      message_template_id: yup
        .string()
        .required("message_template_id это обязательное поле"),
      message_type_id: yup
        .string()
        .required("message_type_id это обязательное поле"),
      sender_name_id: yup
        .string()
        .required("sender_name_id это обязательное поле"),
      message_status_id: yup
        .string()
        .required("message_status_id это обязательное поле"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "add" ? "Добавление" : "Измение"} Отправленных сообщений
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Посмотреть отправленные сообщения</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Номер</label>
            <Form.Control
              as="select"
              name="user_contact_id"
              className="form-control"
              value={formik.values.user_contact_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.user_contact_id &&
                !!formik.errors.user_contact_id
              }
              isValid={
                !!formik.touched.user_contact_id &&
                !formik.errors.user_contact_id
              }>
              <option value={0}>Выбрать номер </option>
              {user_contactsData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`user_contacts-${i}`}>
                    {item.phone_number}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.user_contact_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.user_contact_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Шаблон сообщения</label>
            <Form.Control
              as="select"
              name="message_template_id"
              className="form-control"
              value={formik.values.message_template_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.message_template_id &&
                !!formik.errors.message_template_id
              }
              isValid={
                !!formik.touched.message_template_id &&
                !formik.errors.message_template_id
              }>
              <option value={0}>Выбрать шаблон сообщения</option>
              {message_templatesData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`message_templates-${i}`}>
                    {item.template_text}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.message_template_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.message_template_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Тип сообщения</label>
            <Form.Control
              as="select"
              name="message_type_id"
              className="form-control"
              value={formik.values.message_type_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.message_type_id &&
                !!formik.errors.message_type_id
              }
              isValid={
                !!formik.touched.message_type_id &&
                !formik.errors.message_type_id
              }>
              <option value={0}>Выбрать тип сообщения</option>
              {message_typesData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`message_types-${i}`}>
                    {item.type_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.message_type_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.message_type_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Имена отправителей</label>
            <Form.Control
              as="select"
              name="sender_name_id"
              className="form-control"
              value={formik.values.sender_name_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.sender_name_id &&
                !!formik.errors.sender_name_id
              }
              isValid={
                !!formik.touched.sender_name_id && !formik.errors.sender_name_id
              }>
              <option value={0}>Выбрать имена отправителей</option>
              {sender_namesData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`sender_names-${i}`}>
                    {item.sender_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.sender_name_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.sender_name_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Статус сообщения</label>
            <Form.Control
              as="select"
              name="message_status_id"
              className="form-control"
              value={formik.values.message_status_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.message_status_id &&
                !!formik.errors.message_status_id
              }
              isValid={
                !!formik.touched.message_status_id &&
                !formik.errors.message_status_id
              }>
              <option value={0}>Выбрать статус сообщения</option>
              {message_statusData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`message_status-${i}`}>
                    {item.status_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.message_status_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.message_status_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
