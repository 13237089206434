import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUsers {
  id: number;
  username: string;
  password_hash: string;
  role_id?: number;
  JWT_token?: string;
  token_expiry?: Date;
  additional_discount?: number;
}

interface IUsersData {
  list?: Array<IUsers>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IUsersData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "" },
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersList: (state, _action: PayloadAction<IUsersData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetUsersToInit: (state) => {
      Object.assign(state, initialState);
    },
    setUsersMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const { setUsersList, resetUsersToInit, setUsersMessage } =
  usersSlice.actions;

export default usersSlice.reducer;
