
import { APIService } from "services";

import {getAllOperators, searchOperators} from "./operatorsService";
export const getBalance = async (id) => {
    let res;
    res = await getFreezeMoney(id);
    if(res && res.data){
        return res.data.balance;
    }else{
        return { records:[], totalCount:0 }
    }

}

export const getFreezeMoney = (id) => {
    return APIService.api().get(`/balance/${id}`)
}