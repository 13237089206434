import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {getBalance} from "../../../services/balanceService";
import {useDispatch} from "react-redux";

interface ITemplateProperty {
  sidebar: boolean;
  activeMenu: string;
  topActiveMenu: string;
  balance: number;
}

const initialState: ITemplateProperty = {
  sidebar: false,
  activeMenu: "/dashboard",
  topActiveMenu: "",
  balance: 0
};

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    sidebarToggle: (state) => {
      state.sidebar = !state.sidebar;
      return state;
    },
    activateMenu: (state, _action: PayloadAction<string>) => {
      if (_action.payload === state.activeMenu) state.activeMenu = "";
      else {
        state.activeMenu = _action.payload;
      }
      return state;
    },
    activateTopMenu: (state, _action: PayloadAction<string>) => {
      if (_action.payload === state.topActiveMenu) state.topActiveMenu = "";
      else state.topActiveMenu = _action.payload;
      return state;
    },
    setBalance: (state, _action: PayloadAction<number>) => {
      state.balance = _action.payload
    },
  },
});

export const { sidebarToggle, activateMenu, activateTopMenu, setBalance } =
  templateSlice.actions;

export default templateSlice.reducer;