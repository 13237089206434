import { APIService } from "services";
import { IErrorCode } from "redux/slices/error_reference";

interface getErrorCodesResponse {
  data: {
    records: IErrorCode[];
    pageNo: number;
    pageSize: number;
    totalCount: number;
  };
}
export const getErrorCodes = (pageNo, pageSize) => {
  return APIService.api().get<any, getErrorCodesResponse>(
    `/error_codes/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getErrorCode = (id) => {
  return APIService.api().get(`/error_codes/${id}`);
};

export const createErrorCode = (data) => {
  return APIService.api().post(`/error_codes`, data);
};

export const updateErrorCode = (id, data) => {
  return APIService.api().patch(`/error_codes/${id}`, data);
};

export const deleteErrorCode = (id) => {
  return APIService.api().delete(`/error_codes/${id}`);
};
