import { APIService } from "services";

export const getMessage_Types = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllMessage_Types(pageNo, pageSize);
  } else {
    try {
      res = await searchMessage_Types(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const addMessage_Types = (data) => {
  return APIService.api().post(`/message_types`, data);
};
export const updateMessage_Types = (id, data) => {
  return APIService.api().patch(`/message_types/${id}`, data);
};
export const getAllMessage_Types = (pageNo, pageSize) => {
  return APIService.api().get(
    `/message_types/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getOneMessage_Types = (id) => {
  return APIService.api().get(`/message_types/${id}`);
};
export const searchMessage_Types = (searchKey, pageNo, pageSize) => {
  return APIService.api().get(
    `/message_types/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const deleteMessage_Types = (id) => {
  return APIService.api().delete(`/message_types/${id}`);
};
