import React, { useEffect, useState } from "react";
import { Badge, Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { setMailingsMessage } from "../../redux/slices/mailings";
import { getOneCompanies } from "../../services/companiesService";
import { Constant } from "../../template/Constant";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  getAllUser_Contacts,
  getByCompanyId,
} from "../../services/user_contactsService";
import { setCompaniesMessage } from "../../redux/actions";

type Props = {
  row?: any;
  handleListBtnClick: () => void;
};

export const CompaniesShow: React.FC<Props> = ({ handleListBtnClick, row }) => {
  const [companyRow, setCompanyRow] = useState(null);
  const [contactsData, setContactsData] = useState(null);

  const getData = () => {
    getOneCompanies(row.id).then((response) => {
      if (response) {
        setCompanyRow(response.data);
      } else {
        setCompaniesMessage({
          text: "No Record Found",
          type: Constant.alertVariantDanger,
        });
      }
    });
  };

  const getUserContacts = (perPage, page) => {
    getByCompanyId(row.id, page, perPage).then((response) => {
      if (response) {
        setContactsData(response.data);
      } else {
        setCompaniesMessage({
          text: "No Record Found",
          type: Constant.alertVariantDanger,
        });
      }
    });
  };

  useEffect(() => {
    if (!companyRow) {
      getData();
    }

    if (!contactsData) {
      getUserContacts(10, 1);
    }
  }, [companyRow, contactsData]);

  const columns = [
    { name: "id", selector: (row) => row.id, sortable: true },
    {
      name: "phone_number",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    { name: "error", selector: (row) => row.import_error, sortable: true },
  ];

  const handlePerRowsChange = async (newPerPage, page) => {
    getUserContacts(newPerPage, page);
  };

  const handlePageChange = (page) => {
    getUserContacts(contactsData.pageSize, page);
  };

  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Компании #{row.id}
          <Button
            className="btn-icon-split float-right"
            onClick={handleListBtnClick}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Посмотреть компании</span>
          </Button>
        </h6>
      </Card.Header>
      {companyRow ? (
        <Card.Body>
          <label className="form -control-label">Название компании</label>
          <p>{companyRow.name}</p>
          <label className="form -control-label">Контакты</label>
          <DataTable
            columns={columns}
            paginationPerPage={Constant.defaultPageSize}
            paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
            data={contactsData?.records || []}
            onChangeRowsPerPage={handlePerRowsChange}
            paginationTotalRows={contactsData?.totalCount || 0}
            className="table table-bordered"
            pagination
            paginationServer
            onChangePage={handlePageChange}></DataTable>
        </Card.Body>
      ) : null}
    </Card>
  );
};
