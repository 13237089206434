import { APIService } from "services";

export const getMessage_Status = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllMessage_Status(pageNo, pageSize);
  } else {
    try {
      res = await searchMessage_Status(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const addMessage_Status = (data) => {
  return APIService.api().post(`/message_status`, data);
};
export const updateMessage_Status = (id, data) => {
  return APIService.api().patch(`/message_status/${id}`, data);
};
export const getAllMessage_Status = (pageNo, pageSize) => {
  return APIService.api().get(
    `/message_status/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getOneMessage_Status = (id) => {
  return APIService.api().get(`/message_status/${id}`);
};
export const searchMessage_Status = (searchKey, pageNo, pageSize) => {
  return APIService.api().get(
    `/message_status/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const deleteMessage_Status = (id) => {
  return APIService.api().delete(`/message_status/${id}`);
};
