import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form } from "react-bootstrap";
import {
  resetMessage_TypesToInit,
  resetOperatorsToInit,
  resetUsersToInit,
  setMessage_TemplatesMessage,
  setMessage_TypesList,
  setOperatorsList,
  setUsersList,
  setUsersMessage,
} from "redux/actions";

import { getOperators } from "services/operatorsService";
import { getMessage_Types } from "services/message_typesService";

import { useAppDispatch } from "redux/store";
import {
  addMessage_Templates,
  updateMessage_Templates,
} from "services/message_templatesService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import {
  resetSmsProvidersToInit,
  setSmsProvidersList,
  setSmsProvidersMessages,
} from "../../redux/slices/sms_providers";
import { getSmsProviders } from "../../services/smsProviders.service";
import { getUsers } from "../../services/usersService";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const Message_TemplatesForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    template_text: "",
    operators: [],
    series_id: 0,
    providers: [],
    users: [],
  };
  const initialValue = action === "edit" ? row : iValue;
  const operatorsData = useSelector((state: RootState) => state.operators);
  const usersData = useSelector((state: RootState) => state.users);
  const message_typesData = useSelector(
    (state: RootState) => state.message_types
  );
  const providersData = useSelector((state: RootState) => state.sms_providers);

  useEffect(() => {
    if (
      operatorsData &&
      operatorsData.list &&
      operatorsData.list.length === 0
    ) {
      dispatch(resetOperatorsToInit());
      getOperators(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setOperatorsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setMessage_TemplatesMessage({
              text: "No Record Found For Operators",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
    if (usersData && usersData.list && usersData.list.length === 0) {
      dispatch(resetUsersToInit());
      getUsers(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setUsersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setUsersMessage({
              text: "No Record Found For Operators",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
    if (
      providersData &&
      providersData.list &&
      providersData.list.length === 0
    ) {
      dispatch(resetSmsProvidersToInit());
      getSmsProviders(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSmsProvidersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSmsProvidersMessages({
              text: "No Record Found For Operators",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
    if (
      message_typesData &&
      message_typesData.list &&
      message_typesData.list.length === 0
    ) {
      dispatch(resetMessage_TypesToInit());
      getMessage_Types(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setMessage_TypesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setMessage_TemplatesMessage({
              text: "No Record Found For Message_Types",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
  }, []);
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      console.log("Validation errors:", formik.errors);
      console.log(action, values);

      if (action === "edit") {
        const response = await updateMessage_Templates(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setMessage_TemplatesMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setMessage_TemplatesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addMessage_Templates(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setMessage_TemplatesMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setMessage_TemplatesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      ...(action === "edit"
        ? {}
        : {
            template_text: yup
              .string()
              .required("template_text это обязательное поле"),
            operators: yup
              .array()
              .min(1)
              .required("Операторы это обязательное поле"),
            providers: yup
              .array()
              .min(1)
              .required("Провайдеры это обязательное поле"),
          }),
      users: yup
        .array()
        .min(1)
        .required("Поле пользователи обязательно для заполнения"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "add" ? "Добавить" : "Изменить"} шаблоны сообщений
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Посмотреть шаблоны сообщений</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Текст шаблона</label>
            <Form.Control
              type="text"
              name="template_text"
              className="form-control"
              value={formik.values.template_text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={action === "edit"}
              isInvalid={
                !!formik.touched.template_text && !!formik.errors.template_text
              }
              isValid={
                !!formik.touched.template_text && !formik.errors.template_text
              }
            ></Form.Control>
            {formik.errors.template_text && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.template_text}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {/*<Form.Group>*/}
          {/*  <label className="form -control-label">series_id</label>*/}
          {/*  <Form.Control*/}
          {/*    type="number"*/}
          {/*    name="series_id"*/}
          {/*    className="form-control"*/}
          {/*    value={formik.values.series_id}*/}
          {/*    onChange={formik.handleChange}*/}
          {/*    onBlur={formik.handleBlur}*/}
          {/*    isInvalid={*/}
          {/*      !!formik.touched.series_id && !!formik.errors.series_id*/}
          {/*    }*/}
          {/*    isValid={*/}
          {/*      !!formik.touched.series_id && !formik.errors.series_id*/}
          {/*    }></Form.Control>*/}
          {/*  {formik.errors.series_id && (*/}
          {/*    <Form.Control.Feedback type="invalid">*/}
          {/*      <>{formik.errors.series_id}</>*/}
          {/*    </Form.Control.Feedback>*/}
          {/*  )}*/}
          {/*</Form.Group>*/}
          <Form.Group>
            <label className="form -control-label">Оператор</label>
            <Form.Control
              as="select"
              name="operators"
              multiple
              className="form-control"
              value={formik.values.operator_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={action === "edit"}
              isInvalid={
                !!formik.touched.operator_id && !!formik.errors.operator_id
              }
              isValid={
                !!formik.touched.operator_id && !formik.errors.operator_id
              }
            >
              {operatorsData.list
                .filter((o) => o.parent_id === null)
                .map((item, i) => {
                  return (
                    <option value={item.id} key={`operators-${i}`}>
                      {item.operator_name}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.errors.operator_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.operator_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Поставщик</label>
            <Form.Control
              as="select"
              name="providers"
              className="form-control"
              multiple
              value={formik.values.provider_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={action === "edit"}
              isInvalid={
                !!formik.touched.provider_id && !!formik.errors.provider_id
              }
              isValid={
                !!formik.touched.provider_id && !formik.errors.provider_id
              }
            >
              {providersData.list.map((item, i) => {
                return (
                  <option value={item.id} key={`provider-${i}`}>
                    {item.provider}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.message_type_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.provider_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Пользователи</label>
            <Form.Control
              as="select"
              multiple
              name="users"
              className="form-control"
              value={formik.values.users}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.users && !!formik.errors.users}
              isValid={!!formik.touched.users && !formik.errors.users}
            >
              {usersData.list.map((item, i) => {
                return (
                  <option value={item.id} key={`provider-${i}`}>
                    {item.username}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.users && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.users}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button type="submit" className="float-right" variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
