import { APIService } from "services";

export const getSeries = async (pageNo, pageSize, search) => {
    let res;

    res = await getAllRoles(pageNo, pageSize);

    if (res && res.data) {
        return res.data;
    } else {
        return { records: [], totalCount: 0 };
    }
}

export const getAllRoles = (pageNo, pageSize) => {
    return APIService.api().get(`/operators/series/all`);
};


export const deleteSeries = (id) => {
    return APIService.api().delete(`/operators/series/${id}`);
};