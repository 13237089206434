import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICompanies {
  id: number;
  name: string;
  date_created?: Date;
}

interface ICompaniesData {
  list?: Array<ICompanies>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: ICompaniesData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "succsess" },
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompaniesList: (state, _action: PayloadAction<ICompaniesData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetCompaniesToInit: (state) => {
      state = initialState;
    },
    setCompaniesMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const { setCompaniesList, resetCompaniesToInit, setCompaniesMessage } =
  companiesSlice.actions;

export default companiesSlice.reducer;
