import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISender_Names {
  id: number;
  sender_name: string;
  user_id?: number;
  id_Value?: string;
  operators?: IOperators[];
  providers?: IProviders[];
}

interface IOperators {
  id: number;
  is_default?: number;
  is_moderated?: number;
  operator_name?: string;
  parent_id?: number;
}

interface IProviders {
  id: number;
  name: string;
  provider: string;
}

interface ISender_NamesData {
  list?: Array<ISender_Names>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: ISender_NamesData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "success" },
};

const sender_namesSlice = createSlice({
  name: "sender_names",
  initialState,
  reducers: {
    setSender_NamesList: (state, _action: PayloadAction<ISender_NamesData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetSender_NamesToInit: (state) => {
      state = initialState;
    },
    setSender_NamesMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const {
  setSender_NamesList,
  resetSender_NamesToInit,
  setSender_NamesMessage,
} = sender_namesSlice.actions;

export default sender_namesSlice.reducer;
