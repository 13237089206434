import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form } from "react-bootstrap";
import {
  resetMessage_TypesToInit,
  resetOperatorsToInit,
  setMessage_TypesList,
  setMessage_TypesMessage,
  setOperatorsList,
  setOperatorsMessage,
  setStep_DiscountsMessage,
} from "redux/actions";

import { useAppDispatch } from "redux/store";
import {
  addStep_Discounts,
  updateStep_Discounts,
} from "services/step_discountsService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { getOperators } from "../../services/operatorsService";
import {
  resetSmsProvidersToInit,
  setSmsProvidersList,
  setSmsProvidersMessages,
} from "../../redux/slices/sms_providers";
import { getSmsProviders } from "../../services/smsProviders.service";
import { getMessage_Types } from "../../services/message_typesService";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const Step_DiscountsForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    operator_id: 0,
    message_type_id: 0,
    provider_id: 0,
    min_count: 0,
    max_count: 0,
    discount_percent: 10,
    is_discount: "0",
  };
  const initialValue = action === "edit" ? row : iValue;

  const operatorsData = useSelector((state: RootState) => state.operators);

  const messageTypesData = useSelector(
    (state: RootState) => state.message_types
  );

  const providersData = useSelector((state: RootState) => state.sms_providers);

  useEffect(() => {
    if (
      operatorsData &&
      operatorsData.list &&
      operatorsData.list.length === 0
    ) {
      dispatch(resetOperatorsToInit());

      getOperators(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setOperatorsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setOperatorsMessage({
              text: "Не найдены записи  для скидок на тарифы",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }

    if (
      messageTypesData &&
      messageTypesData.list &&
      messageTypesData.list.length === 0
    ) {
      dispatch(resetMessage_TypesToInit());

      getMessage_Types(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setMessage_TypesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setMessage_TypesMessage({
              text: "Не найдены записи  для скидок на тарифы",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }

    if (
      providersData &&
      providersData.list &&
      providersData.list.length === 0
    ) {
      dispatch(resetSmsProvidersToInit());

      getSmsProviders(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSmsProvidersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSmsProvidersMessages({
              text: "Не найдены записи  для скидок на тарифы",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
  }, []);
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateStep_Discounts(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setStep_DiscountsMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setStep_DiscountsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addStep_Discounts(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setStep_DiscountsMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setStep_DiscountsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      operator_id: yup.number().required("Поле обязательно для заполнения"),
      message_type_id: yup
        .number()
        .required(
          "message_type_id должен быть числового типа, но окончательное значение: NaN."
        ),
      provider_id: yup
        .number()
        .required(
          "provider_id должен быть числового типа, но окончательное значение: NaN."
        ),
      min_count: yup
        .number()
        .required(
          "min_count должен быть числового типа, но окончательное значение: NaN."
        ),
      max_count: yup
        .number()
        .required(
          "max_count должен быть числового типа, но окончательное значение: NaN."
        ),
      discount_percent: yup
        .number()
        .required("Значение не может быть равно 0")
        .positive("Значение не может быть равно 0"),
      is_discount: yup
        .number()
        .required(
          "is_discount должен быть числового типа, но окончательное значение: NaN."
        ),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "add" ? "Добавить скидку" : "Изменить скидку"}
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Назад к скидкам на тарифы</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">От</label>
            <Form.Control
              type="number"
              name="min_count"
              className="form-control"
              value={formik.values.min_count}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.min_count && !!formik.errors.min_count
              }
              isValid={
                !!formik.touched.min_count && !formik.errors.min_count
              }></Form.Control>
            {formik.errors.min_count && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.min_count}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form-control-label">До</label>
            <Form.Control
              type="number"
              name="max_count"
              className="form-control"
              value={formik.values.max_count}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.max_count && !!formik.errors.max_count
              }
              isValid={
                !!formik.touched.max_count && !formik.errors.max_count
              }></Form.Control>
            {formik.errors.max_count && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.max_count}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">
              % скидки от базового тарифа
            </label>
            <Form.Control
              type="number"
              name="discount_percent"
              className="form-control"
              value={formik.values.discount_percent}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.discount_percent &&
                !!formik.errors.discount_percent
              }
              isValid={
                !!formik.touched.discount_percent &&
                !formik.errors.discount_percent
              }></Form.Control>
            {formik.errors.discount_percent && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.discount_percent}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">ID оператора</label>
            <Form.Control
              as="select"
              name="operator_id"
              className="form-control"
              value={formik.values.operator_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.operator_id && !!formik.errors.operator_id
              }
              isValid={
                !!formik.touched.operator_id && !formik.errors.operator_id
              }>
              <option value={null}>Выберите оператора</option>
              {operatorsData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`step_tariffs-${i}`}>
                    {item.operator_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.operator_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.operator_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">Тип сообщения</label>
            <Form.Control
              as="select"
              name="message_type_id"
              className="form-control"
              value={formik.values.message_type_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.message_type_id &&
                !!formik.errors.message_type_id
              }
              isValid={
                !!formik.touched.message_type_id &&
                !formik.errors.message_type_id
              }>
              <option value={null}>Выберите тип сообщения</option>
              {messageTypesData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`step_tariffs-${i}`}>
                    {item.type_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.message_type_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.message_type_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">Партнер для рассылок</label>
            <Form.Control
              as="select"
              name="provider_id"
              className="form-control"
              value={formik.values.provider_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.provider_id && !!formik.errors.provider_id
              }
              isValid={
                !!formik.touched.provider_id && !formik.errors.provider_id
              }>
              <option value={null}>Выберите</option>
              {providersData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`step_tariffs-${i}`}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.provider_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.provider_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">
              Тип изменения стоимости
            </label>
            <div role="group">
              <Form.Check
                label="Наценка"
                checked
                name="is_discount"
                type="radio"
                id={`radio-1`}
                value={0}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Form.Check
                label="Скидка"
                name="is_discount"
                type="radio"
                id={`radio-2`}
                value={1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {formik.errors.is_discount && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.is_discount}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
