import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form } from "react-bootstrap";
import {
  resetOperatorsToInit,
  resetUsersToInit,
  setOperatorsList,
  setSender_NamesMessage,
  setSender_NamesList,
  setUsersList,
} from "redux/actions";

import { getUsers } from "services/usersService";
import { getSender_Names } from "services/sender_namesService";
import { getModeratedOperators, getOperators } from "services/operatorsService";

import { useAppDispatch } from "redux/store";
import { addSeries, updateSeries } from "services/sender_namesService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { getSmsProviders } from "../../services/smsProviders.service";
import {
  resetSmsProvidersToInit,
  setSmsProvidersList,
  setSmsProvidersMessages,
} from "../../redux/slices/sms_providers";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const SeriesForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    sender_name: "",
    text: "",
    users: [],
    operators: [],
    providers: [],
  };

  const initialValue = action === "edit" ? row : iValue;
  const sender_NamesData = useSelector(
    (state: RootState) => state.sender_names
  );
  const providerData = useSelector((state: RootState) => state.sms_providers);
  const operatorsData = useSelector((state: RootState) => state.operators);
  const usersData = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (
      sender_NamesData &&
      sender_NamesData.list &&
      sender_NamesData.list.length === 0
    ) {
      dispatch(resetUsersToInit());
      getSender_Names(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setSender_NamesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "No Record Found For Users",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
    if (providerData && providerData.list && providerData.list.length === 0) {
      getSmsProviders(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        dispatch(resetSmsProvidersToInit());
        if (response && response.records) {
          dispatch(
            setSmsProvidersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSmsProvidersMessages({
              text: "No Record Found For Users",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
    if (
      operatorsData &&
      operatorsData.list &&
      operatorsData.list.length === 0
    ) {
      dispatch(resetOperatorsToInit());
      getOperators(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setOperatorsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records.filter((op) => !op.parent_id),
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "No Record Found For Operators",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
    if (usersData && usersData.list && usersData.list.length === 0) {
      dispatch(resetOperatorsToInit());
      getUsers(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setUsersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records.filter((op) => !op.parent_id),
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "No Record Found For Operators",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      ...initialValue,
      sender_name_id: action === "edit" ? initialValue.sender_name_id : sender_NamesData.list[0]?.id.toString(),
    },
    onSubmit: async (values) => {
      values.user_id = [];
      values.user_id.push(
        sender_NamesData.list.find((sender) => {
          return sender.id == values.sender_name_id;
        }).user_id
      );
      if (action === "edit") {
        values.operators =
          typeof formik.values.operators === "string"
            ? formik.values.operators.split(",").map(Number)
            : formik.values.operators;
        // values.user_id =
        //   typeof formik.values.user_id === "string"
        //     ? formik.values.user_id.split(",").map(Number)
        //     : formik.values.user_id;
        const response = await updateSeries(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setSender_NamesMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addSeries(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setSender_NamesMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      text: yup.string().required("Введите текст"),
      sender_name_id: yup.number().required("Выберите имя отправителя"),
      operators: yup.mixed().required("Нужно выбрать хотя бы 1 оператора"),
      //   user_id: yup.mixed().required("Нужно выбрать хотя бы 1 пользователя"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {/* {action} Sender_Names */}
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Вернуться назад к именам отправителей</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Имя отправителя</label>
            <Form.Control
              as="select"
              name="sender_name_id"
              className="form-control mb-2"
              value={formik.values.sender_name_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.sender_name_id}
              isValid={!formik.errors.sender_name_id}
            >
              {sender_NamesData.list.map((item, i) => {
                return (
                  <option value={item.id} key={`users-${i}`}>
                    {item.id_Value ? `${item.sender_name}(${item.id_Value})` :  item.sender_name}
                    {/* {`${item.sender_name}(${item.id_Value})`} */}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.sender_name_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.sender_name_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Оператор</label>
            <Form.Control
              as="select"
              multiple
              name="operators"
              className="form-control"
              value={
                typeof formik.values.operators === "string"
                  ? formik.values.operators.split(",").map(Number)
                  : formik.values.operators
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.operators && !!formik.errors.operators
              }
              isValid={!!formik.touched.operators && !formik.errors.operators}
            >
              {operatorsData.list
                .filter((op) => !op.parent_id)
                .map((item, i) => {
                  return (
                    <option value={item.id} key={`operators-${i}`}>
                      {item.operator_name}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.errors.operators && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.operators}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Текст</label>
            <Form.Control
              type="text"
              name="text"
              className="form-control"
              value={formik.values.text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.text && !!formik.errors.text}
              isValid={!!formik.touched.text && !formik.errors.text}
            ></Form.Control>
            {formik.errors.text && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.text}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {/* <Form.Group>
            <label className="form -control-label">Пользователь</label>
            <Form.Control
              as="select"
              multiple
              name="user_id"
              className="form-control"
              value={
                typeof formik.values.user_id === "string"
                  ? formik.values.user_id.split(",").map(Number)
                  : formik.values.user_id
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.user_id}
              isValid={!formik.errors.user_id}
            >
              {usersData.list
                .filter((name) =>
                  // @ts-ignore
                  {
                    if (JSON.parse(localStorage.userData).is_admin === 1) {
                      return true;
                    } else {
                      // @ts-ignore
                      return name.id === JSON.parse(localStorage.userData).id;
                    }
                  }
                )
                .map((item, i) => {
                  return (
                    <option value={item.id} key={`operators-${i}`}>
                      {item.username}
                    </option>
                  );
                })}
            </Form.Control>
            {formik.errors.user_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.user_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group> */}

          <Form.Group>
            <Button type="submit" className="float-right" variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
