import { APIService } from "services";

export const getMailings = async (
  pageNo,
  pageSize,
  search,
  startDate = null,
  endDate = null
) => {
  let res;
  if (search.length === 0 && !startDate && !endDate) {
    res = await getAllMailings(pageNo, pageSize);
  } else {
    try {
      res = await searchMailings(search, startDate, endDate, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const sendOnceMessage = (data) => {
  return APIService.api().post(`/message`, data);
}

export const addMailings = (data) => {
  return APIService.api().post(`/mailings`, data);
};
export const updateMailings = (id, data) => {
  return APIService.api().patch(`/mailings/${id}`, data);
};
export const getAllMailings = (pageNo, pageSize) => {
  return APIService.api().get(
    `/mailings/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getOneMailings = (id) => {
  return APIService.api().get(`/mailings/${id}`);
};
export const searchMailings = (
  searchKey,
  startDate,
  endDate,
  pageNo,
  pageSize
) => {
  return APIService.api().get(
    `/mailings/search/?pageNo=${pageNo}&pageSize=${pageSize}${
      searchKey ? `&search=${searchKey}` : ""
    }${
      startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
    }`
  );
};
export const deleteMailings = (id) => {
  return APIService.api().delete(`/mailings/${id}`);
};

export const startMailing = (id) => {
  return APIService.api().post(`/mailings/start/${id}`);
};

export const exportMessages = (id) => {
  return APIService.api().get(`/mailings/export/${id}`, {
    responseType: "blob",
  });
};

export const exportMessagesAsClsx = (id: number) => {
  return APIService.api().get(`/mailings/export-xlsx/${id}`, {
    responseType: "blob",
  });
};
