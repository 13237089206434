import { APIService } from "services";

export const getUser_Contacts = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllUser_Contacts(pageNo, pageSize);
  } else {
    try {
      res = await searchUser_Contacts(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const addUser_Contacts = (data) => {
  return APIService.api().post(`/user_contacts`, data);
};
export const updateUser_Contacts = (id, data) => {
  return APIService.api().patch(`/user_contacts/${id}`, data);
};
export const getAllUser_Contacts = (pageNo, pageSize) => {
  return APIService.api().get(
    `/user_contacts/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getOneUser_Contacts = (id) => {
  return APIService.api().get(`/user_contacts/${id}`);
};
export const searchUser_Contacts = (searchKey, pageNo, pageSize) => {
  return APIService.api().get(
    `/user_contacts/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const deleteUser_Contacts = (id) => {
  return APIService.api().delete(`/user_contacts/${id}`);
};

export const importUserContacts = (data, changePercent) => {
  return APIService.api(true).post(`/user_contacts/import`, data, {
    "onUploadProgress": progressEvent => changePercent(Math.round((progressEvent.loaded / progressEvent.total) * 100))
  });
};

export const getByCompanyId = (company_id, pageNo, pageSize) => {
  return APIService.api(true).get(
    `/user_contacts/getby/Company_Id/${company_id}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
