import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setRolesList, setRolesMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getRoles } from "services/rolesService";
import Layout from "template";
import { Constant } from "template/Constant";
import { ProxiesForm } from "./form";
import { ProxiesTable } from "./table";
import { setProxiesList, setProxiesMessage } from "../../redux/slices/proxies";
import { getProxies } from "../../services/proxiesService";

export const Proxies: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.proxies);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");

  const getData = (page, pageSize, searchKey) => {
    getProxies(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(
          setProxiesList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.totalCount,
            searchKey: searchKey,
          })
        );
      } else {
        dispatch(setProxiesMessage("No Record Found"));
      }
    });
  };

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setProxiesMessage(""));
    setAction("edit");
  };
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Прокси</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ? (
            <Alert
              variant={Constant.alertVariantWarning}
              className="alert-dismissible fade">
              {rData.message}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => dispatch(setProxiesMessage(""))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {action ? (
            <ProxiesForm
              hideShowForm={setAction}
              action={action}
              row={row}
              getData={getData}
            />
          ) : (
            <ProxiesTable
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
