import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  setOperatorsList,
  setOperatorsMessage,
  setUsersList,
  setUsersMessage,
} from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getOperators } from "services/operatorsService";
import Layout from "template";
import { UsersTable } from "./table";
import { Constant } from "template/Constant";
import { getUsers, getUsersMoneyMoving } from "../../services/usersService";
import { TransferForm } from "./form";

export const Money_Transfer: React.FC = () => {
  const dispatch = useAppDispatch();
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");

  const getData = (page, pageSize, searchKey) => {
    getUsersMoneyMoving(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(
          setUsersList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.totalCount,
            searchKey: searchKey,
          })
        );
      } else {
        dispatch(
          setUsersMessage({
            text: "No Record Found",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });
  };

  const handleRowEdit = () => {
    dispatch(setUsersMessage({ text: "", type: Constant.alertVariantSuccess }));
    setAction("edit");
  };

  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Баланс</h1>
        </div>

        <div className="d-flex flex-column min-vh-100">
          {action ? (
            <TransferForm
              hideShowForm={setAction}
              action={action}
              row={row}></TransferForm>
          ) : (
            <UsersTable
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
