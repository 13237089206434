import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form } from "react-bootstrap";
import {
  resetMessage_TypesToInit,
  resetOperatorsToInit,
  setMessage_TypesList,
  setOperatorsList,
  setStep_TariffsMessage,
} from "redux/actions";

import { getOperators } from "services/operatorsService";
import { getMessage_Types } from "services/message_typesService";

import { useAppDispatch } from "redux/store";
import {
  addStep_Tariffs,
  updateStep_Tariffs,
} from "services/step_tariffsService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { getSmsProviders } from "../../services/smsProviders.service";
import {
  resetSmsProvidersToInit,
  setSmsProvidersList,
  setSmsProvidersMessages,
} from "../../redux/slices/sms_providers";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const Step_TariffsForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    operator_id: 0,
    message_type_id: 0,
    min_count: 0,
    max_count: 0,
    cost_per_message: 0,
  };
  const initialValue = action === "edit" ? row : iValue;
  const operatorsData = useSelector((state: RootState) => state.operators);
  const message_typesData = useSelector(
    (state: RootState) => state.message_types
  );
  const providerData = useSelector((state: RootState) => state.sms_providers);

  useEffect(() => {
    if (
      operatorsData &&
      operatorsData.list &&
      operatorsData.list.length === 0
    ) {
      dispatch(resetOperatorsToInit());
      getOperators(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setOperatorsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setStep_TariffsMessage({
              text: "No Record Found For Operators",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
    if (
      message_typesData &&
      message_typesData.list &&
      message_typesData.list.length === 0
    ) {
      dispatch(resetMessage_TypesToInit());
      getMessage_Types(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setMessage_TypesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setStep_TariffsMessage({
              text: "No Record Found For Message_Types",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }

    if (providerData && providerData.list && providerData.list.length === 0) {
      getSmsProviders(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        dispatch(resetSmsProvidersToInit());
        if (response && response.records) {
          dispatch(
            setSmsProvidersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSmsProvidersMessages({
              text: "No Record Found For Users",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateStep_Tariffs(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setStep_TariffsMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setStep_TariffsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addStep_Tariffs(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setStep_TariffsMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setStep_TariffsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      min_count: yup.number().nullable(),
      max_count: yup.number().nullable(),
      cost_per_message: yup.number().nullable(),
      operator_id: yup.string().required("operator_id is required"),
      message_type_id: yup.string().required("message_type_id is required"),
      provider_id: yup.string().required("message_type_id is required"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action == "add" ? "Добавить" : "Изменить "} Степ-тарификацию
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Назад к степ-тарифам</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">От</label>
            <Form.Control
              type="number"
              name="min_count"
              className="form-control"
              value={formik.values.min_count}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.min_count && !!formik.errors.min_count
              }
              isValid={
                !!formik.touched.min_count && !formik.errors.min_count
              }></Form.Control>
            {formik.errors.min_count && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.min_count}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">До</label>
            <Form.Control
              type="number"
              name="max_count"
              className="form-control"
              value={formik.values.max_count}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.max_count && !!formik.errors.max_count
              }
              isValid={
                !!formik.touched.max_count && !formik.errors.max_count
              }></Form.Control>
            {formik.errors.max_count && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.max_count}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <label className="form -control-label">Стоимость сообщения</label>
            <Form.Control
              type="number"
              name="cost_per_message"
              className="form-control"
              value={formik.values.cost_per_message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.cost_per_message &&
                !!formik.errors.cost_per_message
              }
              isValid={
                !!formik.touched.cost_per_message &&
                !formik.errors.cost_per_message
              }></Form.Control>
            {formik.errors.cost_per_message && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.cost_per_message}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Оператор</label>
            <Form.Control
              as="select"
              name="operator_id"
              className="form-control"
              value={formik.values.operator_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.operator_id && !!formik.errors.operator_id
              }
              isValid={
                !!formik.touched.operator_id && !formik.errors.operator_id
              }>
              <option value={0}>Выберите оператора</option>
              {operatorsData.list.filter(o => !o.parent_id).map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`operators-${i}`}>
                    {item.operator_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.operator_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.operator_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Тип сообщения</label>
            <Form.Control
              as="select"
              name="message_type_id"
              className="form-control"
              value={formik.values.message_type_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.message_type_id &&
                !!formik.errors.message_type_id
              }
              isValid={
                !!formik.touched.message_type_id &&
                !formik.errors.message_type_id
              }>
              <option value={0}>Выберите тип</option>
              {message_typesData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`message_types-${i}`}>
                    {item.type_name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.message_type_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.message_type_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Партнер для рассылок</label>
            <Form.Control
              as="select"
              name="provider_id"
              className="form-control"
              value={formik.values.provider_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.provider_id && !!formik.errors.provider_id
              }
              isValid={
                !!formik.touched.provider_id && !formik.errors.provider_id
              }>
              <option value={null}>Выберите</option>
              {providerData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`step_tariffs-${i}`}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.provider_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.provider_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
