import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setMailingsList, setMailingsMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getMailings } from "services/mailingsService";
import Layout from "template";
import { Constant } from "template/Constant";
import { MailingsForm } from "./form";
import { MailingsTable } from "./table";
import { MailingsShow } from "./show";

export const Mailings: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.mailings);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");
  const getData = (
    page,
    pageSize,
    searchKey,
    startDate?: any,
    endDate?: any
  ) => {
    getMailings(page, pageSize, searchKey, startDate, endDate).then(
      (response) => {
        if (response && response.records) {
          dispatch(
            setMailingsList({
              pageNo: page,
              pageSize: pageSize,
              list: response.records,
              totalCount: response.totalCount,
              searchKey: searchKey,
            })
          );
        } else {
          dispatch(
            setMailingsMessage({
              text: "No Record Found",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    );
  };

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(
      setMailingsMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    setAction("edit");
  };

  const handleRowShow = (rowData) => {
    setRow(rowData);
    dispatch(
      setMailingsMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    setAction("show");
  };

  const handleListBtnClick = () => {
    dispatch(
      setMailingsMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    setAction("");
  };

  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Рассылки</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message.text ? (
            <Alert
              variant={rData.message.type}
              className="alert-dismissible fade">
              {rData.message.text}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() =>
                  dispatch(
                    setMailingsMessage({
                      text: "",
                      type: Constant.alertVariantSuccess,
                    })
                  )
                }>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {!action ? (
            <MailingsTable
              handleRowShow={handleRowShow}
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
            />
          ) : null}
          {action === "add" || action === "edit" || action === "add-manual" ? (
            <MailingsForm
              hideShowForm={setAction}
              action={action}
              row={row}
              getData={getData}
            />
          ) : null}
          {action === "show" ? (
            <MailingsShow
              handleListBtnClick={handleListBtnClick}
              row={row}
            />
          ) : null}
        </div>
      </div>
    </Layout>
  );
};
