import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setOperatorsList, setOperatorsMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getOperators } from "services/operatorsService";
import Layout from "template";
import { Constant } from "template/Constant";
import { OperatorsForm } from "./form";
import { OperatorsTable } from "./table";
import { useUser } from "hooks/useUser";

export const Operators: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.operators);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");

  const { user } = useUser();
  const getData = (page, pageSize, searchKey) => {
    getOperators(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(
          setOperatorsList({
            pageNo: page,
            pageSize: pageSize,
            list: response.records,
            totalCount: response.totalCount,
            searchKey: searchKey,
          })
        );
      } else {
        dispatch(
          setOperatorsMessage({
            text: "No Record Found",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });
  };

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(
      setOperatorsMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    setAction("edit");
  };
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Операторы</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message.text ? (
            <Alert
              variant={rData.message.type}
              className="alert-dismissible fade">
              {rData.message.text}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() =>
                  dispatch(
                    setOperatorsMessage({
                      text: "",
                      type: Constant.alertVariantSuccess,
                    })
                  )
                }>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {action && user.is_admin ? (
            <OperatorsForm
              hideShowForm={setAction}
              action={action}
              row={row}
              getData={getData}
            />
          ) : (
            <OperatorsTable
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
