import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import {
  resetStep_DiscountsToInit,
  resetStep_TariffsToInit,
  setStep_DiscountsMessage,
} from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteStep_Discounts } from "services/step_discountsService";
import Accordion from "react-bootstrap/Accordion";
import { genStringUUID } from "../../utils/genUUID";

type Props = {
  hideShowForm: (action) => void;
  handleRowEdit: (row) => void;
  getData: (page, pageSize, searchKey) => void;
};
export const Step_DiscountsTable: React.FC<Props> = ({
  hideShowForm,
  handleRowEdit,
  getData,
}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [rowData, setRowData] = useState(undefined);
  const rData = useSelector((state: RootState) => state.step_discounts);
  const [preparedData, setPreparedData] = useState({});

  const handleSearch = () => {
    if (search.length > 0) {
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    await getData(page, newPerPage, "");
  };

  const handlePageChange = (page) => {
    getData(page, rData.pageSize, "");
  };

  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };

  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      dispatch(resetStep_DiscountsToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    }
  }, [rData.list.length]);

  const handleReset = () => {
    setSearch("");
    dispatch(resetStep_DiscountsToInit());
    getData(Constant.defaultPageNumber, rData.pageSize, "");
  };

  const handleServerDelete = async () => {
    if (rowData) {
      const response = await deleteStep_Discounts(rowData.id);
      if (response) {
        dispatch(resetStep_DiscountsToInit());
        dispatch(
          setStep_DiscountsMessage({
            text: "Удалено успешно",
            type: Constant.alertVariantSuccess,
          })
        );
        getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
        setShowDelete(false);
      } else {
        dispatch(
          setStep_DiscountsMessage({
            text: "Ошибка!",
            type: Constant.alertVariantDanger,
          })
        );
      }
    }
  };

  const handleRowSelection = (row) => {
    console.log(row); // Row Selection Functionality can be written here
  };

  const handleAddButtonClick = () => {
    dispatch(
      setStep_DiscountsMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    hideShowForm("add");
  };

  useEffect(() => {
    if (rData && rData.list && rData.list.length === 0) {
      dispatch(resetStep_TariffsToInit());
      getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
    } else if (rData && rData.list && rData.list.length !== 0) {
      let prepareData = {};

      rData.list.forEach((_data) => {
        if (!prepareData[_data.operator_name]) {
          console.log("init", _data.operator_name);

          prepareData[_data.operator_name] = {};
        }

        if (!prepareData[_data.operator_name][_data.provider_name]) {
          console.log("init", _data.operator_name, _data.provider_name);
          prepareData[_data.operator_name][_data.provider_name] = {};
        }

        if (
          !prepareData[_data.operator_name][_data.provider_name][
            _data.type_name
          ]
        ) {
          console.log(
            "init",
            _data.operator_name,
            _data.provider_name,
            _data.type_name
          );

          prepareData[_data.operator_name][_data.provider_name][
            _data.type_name
          ] = [];
        }

        prepareData[_data.operator_name][_data.provider_name][
          _data.type_name
        ].push(_data);
      });

      console.log(prepareData);

      setPreparedData(prepareData);
    }
  }, [rData.list.length]);

  const columns = [
    { name: "id", selector: (row) => row.id, sortable: true },
    {
      name: "Минимальное значение",
      selector: (row) => row.min_count,
      sortable: true,
    },
    {
      name: "Максимальное значение",
      selector: (row) => row.max_count,
      sortable: true,
    },
    {
      name: "Размер скидки",
      selector: (row) => row.discount_percent,
      sortable: true,
    },

    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowEdit(row)}>
          Изменить
        </Button>
      ),
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowDeleteClick(row)}>
          Удалить
        </Button>
      ),
    },
  ];
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          Список скидок ({rData.totalCount})
          <Button
            variant="light"
            className="btn-circle btn-sm ml-2"
            onClick={handleReset}>
            <i className="fa fa-refresh"></i>
          </Button>
          <Button
            className="btn-icon-split float-right"
            onClick={handleAddButtonClick}>
            <span className="icon text-white-50">
              <i className="fas fa-add"></i>
            </span>
            <span className="text">Добавить новую</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Col
          className="ml-auto"
          md={3}
          xs={12}
          xl={3}>
          <InputGroup>
            <Form.Control
              placeholder="Поиск"
              aria-label="Search"
              aria-describedby="basic-search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              disabled={search.length <= 2}
              variant="dark"
              className="rounded-0 rounded-right"
              id="button-search"
              onClick={handleSearch}>
              Поиск
            </Button>
          </InputGroup>
        </Col>
        <Accordion
          defaultActiveKey="0"
          style={{ gap: "8px", display: "flex", flexDirection: "column" }}>
          {Object.keys(preparedData).map((key) => {
            const _key = genStringUUID();
            const operator = preparedData[key];

            return (
              <Accordion.Item eventKey={_key}>
                <Accordion.Header
                  style={{
                    all: "unset",
                  }}>
                  <Button>{key}</Button>
                </Accordion.Header>
                <Accordion.Body>
                  {Object.keys(operator).map((operatorKey) => {
                    const providers = operator[operatorKey];
                    const _operatorKey = genStringUUID();

                    return (
                      <Accordion>
                        <Accordion.Item eventKey={_operatorKey}>
                          <Accordion.Header>
                            <Button variant="info">{operatorKey}</Button>
                          </Accordion.Header>
                          <Accordion.Body>
                            {Object.keys(providers).map((providerKey) => {
                              const tariffs = providers[providerKey];
                              const _providerKey = genStringUUID();

                              return (
                                <Accordion>
                                  <Accordion.Item eventKey={_providerKey}>
                                    <Accordion.Header>
                                      <Button variant="danger">
                                        {providerKey}
                                      </Button>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <DataTable
                                        noDataComponent="Нет записей для отображения"
                                        columns={columns}
                                        data={tariffs}></DataTable>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>{" "}
      </Card.Body>
      <ConfirmationModal
        buttonNegative=" Отменить"
        buttonPositive="Удалить"
        title="Подтверждение удаления"
        show={showDelete}
        body={"Вы уверены?"}
        onNegative={() => setShowDelete(false)}
        onPositive={handleServerDelete}
      />
    </Card>
  );
};
