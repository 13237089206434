import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISender_Names {
  id_Value: string;
  id: number;
  sender_name: string;
  user_id?: number;
  operators?: number[];
}

interface IModeratedSenderNamesData {
  list?: Array<ISender_Names>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IModeratedSenderNamesData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "success" },
};

const moderatedSenderNamesSlice = createSlice({
  name: "sender_names",
  initialState,
  reducers: {
    setModeratedSenderNamesList: (
      state,
      _action: PayloadAction<IModeratedSenderNamesData>
    ) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetModeratedSenderNamesToInit: (state) => {
      state = initialState;
    },
    setModeratedSenderNamesMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const {
  setModeratedSenderNamesList,
  resetModeratedSenderNamesToInit,
  setModeratedSenderNamesMessage,
} = moderatedSenderNamesSlice.actions;

export default moderatedSenderNamesSlice.reducer;
