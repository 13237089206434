import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUser_Contacts {
  id: number;
  user_id?: number;
  phone_number: string;
  operator_id?: number;
  company_id?: number;
  date_added?: Date;
  last_updated_operator?: Date;
}

interface IUser_ContactsData {
  list?: Array<IUser_Contacts>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IUser_ContactsData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "success" },
};

const user_contactsSlice = createSlice({
  name: "user_contacts",
  initialState,
  reducers: {
    setUser_ContactsList: (
      state,
      _action: PayloadAction<IUser_ContactsData>
    ) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetUser_ContactsToInit: (state) => {
      state = initialState;
    },
    setUser_ContactsMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const {
  setUser_ContactsList,
  resetUser_ContactsToInit,
  setUser_ContactsMessage,
} = user_contactsSlice.actions;

export default user_contactsSlice.reducer;
