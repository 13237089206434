import React, { useEffect, useState } from "react";
import Layout from "../../template";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ConfirmationModal from "../../template/ConfirmationModal";
import {
  generateNewToken,
  getTokens,
  removeToken,
} from "../../services/tokenService";

export const ApiKeys: React.FC = () => {
  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState({} as any);
  const [showDelete, setShowDelete] = useState(false);
  const [tryGet, setTryGet] = useState(false);

  const getData = () => {
    getTokens().then((data) => {
      setData(data.data);
    });
  };

  const handleRowDeleteClick = (row) => {
    setRowData(row);
    setShowDelete(true);
  };

  const handleServerDelete = async () => {
    if (rowData) {
      await removeToken(rowData.id);

      getData();

      setShowDelete(false);
    }
  };

  const handleAddButtonClick = async () => {
    await generateNewToken();

    getData();
  };

  const copy = async (api_key: string) => {
    await navigator.clipboard.writeText(api_key);

    alert("Copied");
  };

  useEffect(() => {
    if (data.length === 0 && !tryGet) {
      getData();

      setTryGet(true);
    }
  });

  const columns = [
    {
      name: "key",
      cell: (row) => (
        <span
          style={{
            cursor: "pointer",
            display: "inline-flex",
            gap: "5px",
            padding: "5px 0",
          }}
          onClick={() => copy(row.api_key)}>
          <i className="fa-solid fa-copy"></i>
          {row.api_key}
        </span>
      ),
      sortable: true,
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="link"
          className="btn-sm"
          onClick={() => handleRowDeleteClick(row)}>
          Удалить
        </Button>
      ),
    },
  ];

  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">API keys</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          <Card className="shadow mb-4">
            <Card.Header className="py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                <Button
                  className="btn-icon-split float-right"
                  onClick={handleAddButtonClick}>
                  <span className="icon text-white-50">
                    <i className="fas fa-add"></i>
                  </span>
                  <span className="text">Создать новый</span>
                </Button>
              </h6>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={data}
                  className="table table-bordered"></DataTable>
              </div>
            </Card.Body>
            <ConfirmationModal
              buttonNegative="Отменить"
              buttonPositive="Удалить"
              title="Подтверждение удаления"
              show={showDelete}
              body={"Вы уверены?"}
              onNegative={() => setShowDelete(false)}
              onPositive={handleServerDelete}
            />
          </Card>
        </div>
      </div>
    </Layout>
  );
};
