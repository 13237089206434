import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form, ProgressBar } from "react-bootstrap";
import {
  setCompaniesList,
  setOperatorsList,
  setUser_ContactsMessage,
  setUsersList,
  setPercent
} from "redux/actions";

import { getUsers } from "services/usersService";
import { getOperators } from "services/operatorsService";
import { getCompanies } from "services/companiesService";

import { useAppDispatch } from "redux/store";
import {
  addUser_Contacts,
  importUserContacts,
  updateUser_Contacts,
} from "services/user_contactsService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { getShortlinksByUser } from "services/short_linksService";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const User_ContactsForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();

  const iValue = {
    id: 0,
    user_id: 0,
    phone_number: "",
    operator_id: 0,
    company_id: 0,
  };

  const initialValue = action === "edit" ? row : iValue;

  const usersData = useSelector((state: RootState) => state.users);
  const operatorsData = useSelector((state: RootState) => state.operators);
  const companiesData = useSelector((state: RootState) => state.companies);
  const percentProgress = useSelector((state: RootState) => state.loading_file_progress.value);
  const [shortLinks, setShortLinks] = React.useState([]);
  

  useEffect(() => {
    getUsers(
      Constant.defaultPageNumber,
      Constant.defaultDropdownPageSize,
      ""
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setUsersList({
            pageNo: Constant.defaultPageNumber,
            pageSize: Constant.defaultDropdownPageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: "",
          })
        );
      } else {
        dispatch(
          setUser_ContactsMessage({
            text: "No Record Found For Users",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });
    getShortlinksByUser().then((response) => {
      console.log('response', response)
      if (response && response.data) {
        setShortLinks(response.data);
      }
    });

    getOperators(
      Constant.defaultPageNumber,
      Constant.defaultDropdownPageSize,
      ""
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setOperatorsList({
            pageNo: Constant.defaultPageNumber,
            pageSize: Constant.defaultDropdownPageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: "",
          })
        );
      } else {
        dispatch(
          setUser_ContactsMessage({
            text: "No Record Found For Operators",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });

    getCompanies(
      Constant.defaultPageNumber,
      Constant.defaultDropdownPageSize,
      ""
    ).then((response) => {
      if (response && response.records) {
        dispatch(
          setCompaniesList({
            pageNo: Constant.defaultPageNumber,
            pageSize: Constant.defaultDropdownPageSize,
            list: response.records,
            totalCount: response.total_count,
            searchKey: "",
          })
        );
      } else {
        dispatch(
          setUser_ContactsMessage({
            text: "No Record Found For Companies",
            type: Constant.alertVariantDanger,
          })
        );
      }
    });
  }, [action]);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateUser_Contacts(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setUser_ContactsMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setUser_ContactsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addUser_Contacts(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setUser_ContactsMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setUser_ContactsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      phone_number: yup.string().required("Поле обязательно для заполнения"),
      company_id: yup.string().required("Поле обязательно для заполнения"),
    }),
  });

  const changePercent = (percent: number) => {
    dispatch(
      setPercent(percent)
    )
  }

  const formikImport = useFormik({
    initialValues: {
      fileData: [],
      shortlink: "",
    },
    onSubmit: async (values) => {
      const data = new FormData();

      data.append("file", values.fileData[0]);
      data.append('shortlink', values.shortlink);

      const response = await importUserContacts(data, changePercent);
      if (response && (response.status === 200 || response.status === 201)) {
        changePercent(0)
        dispatch(
          setUser_ContactsMessage({
            text: `Файл загружен успешно! Номера успешно: ${response.data.success_rows}. Номера с ошибкой: ${response.data.error_rows}. Всего номеров: ${response.data.total_rows}.`,
            type: Constant.alertVariantSuccess,
          })
        );
        hideShowForm("");
      } else {
        dispatch(
          setUser_ContactsMessage({
            text: "Ошибка!",
            type: Constant.alertVariantDanger,
          })
        );
      } 
    },
    validationSchema: yup.object().shape({
      fileData: yup
        .array()
        .required()
        .test(
          "fileType",
          "Поддерживаемые форматы файлов : CSV, XLSX, XLS",
          (value) => {
            return (
              value[0]?.name?.includes(".csv") ||
              value[0]?.name?.includes(".xlsx") ||
              value[0]?.name?.includes(".xls")
            );
          }
        ),
    }),
  });

  const mainForm = () => {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group>
          <label className="form -control-label">Номер телефона</label>
          <Form.Control
            type="text"
            name="phone_number"
            className="form-control"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={
              !!formik.touched.phone_number && !!formik.errors.phone_number
            }
            isValid={
              !!formik.touched.phone_number && !formik.errors.phone_number
            }></Form.Control>
          {formik.errors.phone_number && (
            <Form.Control.Feedback type="invalid">
              <>{formik.errors.phone_number}</>
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <label className="form -control-label">Рекламная компания</label>
          <Form.Control
            as="select"
            name="company_id"
            className="form-control"
            value={formik.values.company_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={
              !!formik.touched.company_id && !!formik.errors.company_id
            }
            isValid={!!formik.touched.company_id && !formik.errors.company_id}>
            <option value={0}>Выберите компанию</option>
            {companiesData.list.map((item, i) => {
              return (
                <option
                  value={item.id}
                  key={`companies-${i}`}>
                  {item.name}
                </option>
              );
            })}
          </Form.Control>
          {formik.errors.company_id && (
            <Form.Control.Feedback type="invalid">
              <>{formik.errors.company_id}</>
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group>
          <Button
            type="submit"
            className="float-right"
            variant="primary">
            Сохранить
          </Button>
        </Form.Group>
      </Form>
    );
  };

  useEffect(() => {
    console.log(formikImport.values);
  }, [formikImport.values]);

  const importForm = () => {
    return (
      <Form onSubmit={formikImport.handleSubmit}>
        <Form.Group>
          <Form.Label className="form-control-label">
            {percentProgress > 0
              ? "Загрузка..."
              : "Загрузите csv, xlsx, xlss file"}
          </Form.Label>
          {percentProgress > 0 ? (
            <ProgressBar
              animated
              now={percentProgress}
              label={`${percentProgress}%`}
            />
          ) : (
            <Form.Control
              accept={"text/csv, .xlsx, .xls"}
              type="file"
              name={"csv_import"}
              size="lg"
              onChange={(event: React.ChangeEvent) => {
                const dFile = event.target as HTMLInputElement;
                if (dFile.files && dFile.files[0]) {
                  formikImport.setFieldValue(
                    "fileData",
                    Array.from(dFile.files)
                  );
                }
              }}
              onBlur={formikImport.handleBlur}
              isInvalid={
                !!formikImport.touched.fileData &&
                !!formikImport.errors.fileData
              }
            />
          )}
          {formikImport.errors.fileData && (
            <Form.Control.Feedback type="invalid">
              <>{formikImport.errors.fileData}</>
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <label className="form -control-label">Короткая ссылка</label>
          <Form.Control
            as="select"
            name="shortlink"
            className="shortlink"
            value={formikImport.values.shortlink}
            onChange={formikImport.handleChange}
            onBlur={formikImport.handleBlur}
            isInvalid={
              !!formikImport.touched.shortlink &&
              !!formikImport.errors.shortlink
            }
            isValid={
              !!formikImport.touched.shortlink && !formikImport.errors.shortlink
            }
          >
            <option value={0}>Выберите короткую ссылку</option>
            {shortLinks &&
              shortLinks.map((item, i) => {
                return (
                  <option value={item.id} key={`shortlink-${i}`}>
                    {item.shortlink}
                  </option>
                );
              })}
          </Form.Control>
          {formikImport.errors.shortlink && (
            <Form.Control.Feedback type="invalid">
              <>{formikImport.errors.shortlink}</>
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Button
            type="submit"
            className="float-right mt-3"
            variant="primary"
            disabled={percentProgress > 0}
          >
            Загрузить
          </Button>
        </Form.Group>
      </Form>
    );
  };

  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Вернуться назад к списку контактов</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>{action !== "import" ? mainForm() : importForm()}</Card.Body>
    </Card>
  );
};
