import { APIService } from "services";

export const getRoles = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllRoles(pageNo, pageSize);
  } else {
    try {
      res = await searchRoles(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const addRoles = (data) => {
  return APIService.api().post(`/roles`, data);
};
export const updateRoles = (id, data) => {
  return APIService.api().patch(`/roles/${id}`, data);
};
export const getAllRoles = (pageNo, pageSize) => {
  return APIService.api().get(`/roles/?pageNo=${pageNo}&pageSize=${pageSize}`);
};
export const getOneRoles = (id) => {
  return APIService.api().get(`/roles/${id}`);
};
export const searchRoles = (searchKey, pageNo, pageSize) => {
  return APIService.api().get(
    `/roles/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const deleteRoles = (id) => {
  return APIService.api().delete(`/roles/${id}`);
};
