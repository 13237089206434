import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IMailings {
  id: number;
  description?: string;
  company_id?: number;
  type_id?: number;
  date_created?: Date;
  date_started?: Date;
}

interface IMailingsData {
  list?: Array<IMailings>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IMailingsData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "success" },
};

const mailingsSlice = createSlice({
  name: "mailings",
  initialState,
  reducers: {
    setMailingsList: (state, _action: PayloadAction<IMailingsData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetMailingsToInit: (state) => {
      state = initialState;
    },
    setMailingsMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const { setMailingsList, resetMailingsToInit, setMailingsMessage } =
  mailingsSlice.actions;

export default mailingsSlice.reducer;
