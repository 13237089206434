import { isRoutePermitted, TUserRole, USER_ROLES } from "../consts/auth.consts";

interface MenuItem {
  title: string;
  path: string;
  icon: string;
  subMenu: MenuItem[];
  roles?: TUserRole[];
}

export const MenuItems: MenuItem[] = [
  {
    title: "Панель управления",
    path: "/dashboard",
    icon: "fas fa-fw fa-tachometer-alt",
    subMenu: [],
  },
  {
    title: "API ключи",
    path: "/api_keys",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [],
  },
  {
    title: "Пользователи",
    path: "/users",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: "Контакты для рассылки",
    path: "/user_contacts",
    icon: "fas fa-fw fa-table",
    subMenu: [],
  },
  // {
  //   title: "Стоимость рассылки",
  //   path: "/transactions",
  //   icon: "fas fa-fw fa-table",
  //   subMenu: [],
  //   roles: [USER_ROLES.ADMIN],
  // },
  {
    title: "Степ-тарификация",
    path: "/step_tariffs",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: "Скидки на тарифы",
    path: "/step_discounts",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: "Отправл. сообщения",
    path: "/sent_messages",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: "Имена отправителей",
    path: "/sender_names",
    icon: "fas fa-fw fa-table",
    subMenu: [],
  },
  {
    title: "Серии",
    path: "/series",
    icon: "fas fa-fw fa-table",
    subMenu: [],
  },
  {
    title: "Роли",
    path: "/roles",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: "Операторы",
    path: "/operators",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [],
  },
  {
    title: "Типы сообщений",
    path: "/message_types",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: "Проверка шаблонов",
    path: "/message_templates",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [],
  },
  {
    title: "Статусы сообщений",
    path: "/message_status",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: "Рассылки",
    path: "/mailings",
    icon: "fas fa-fw fa-table",
    subMenu: [],
  },
  {
    title: "Компании",
    path: "/companies",
    icon: "fas fa-fw fa-table",
    subMenu: [],
  },
  {
    title: "Баланс",
    path: "/money_transfer",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: "Прокси",
    path: "/proxies",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: "Справочник ошибок",
    path: "/error_reference",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
  {
    title: "Короткие ссылки",
    path: "/short_links",
    icon: "fas fa-fw fa-table",
    subMenu: [],
    roles: [USER_ROLES.ADMIN],
  },
];

export const getMenuItems = (user) => {
  return MenuItems.filter((item) => {
    if (item.roles) {
      return isRoutePermitted(user, item.roles);
    }
    return true;
  });
};
