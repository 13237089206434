import { APIService } from "services";

export const getMessage_Templates = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllMessage_Templates(pageNo, pageSize);
  } else {
    try {
      res = await searchMessage_Templates(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const addMessage_Templates = (data) => {
  return APIService.api().post(`/message_templates`, data);
};
export const updateMessage_Templates = (id, data) => {
  return APIService.api().patch(`/message_templates/${id}`, data);
};
export const getAllMessage_Templates = (pageNo, pageSize) => {
  return APIService.api().get(
    `/message_templates/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getOneMessage_Templates = (id) => {
  return APIService.api().get(`/message_templates/${id}`);
};
export const searchMessage_Templates = (searchKey, pageNo, pageSize) => {
  return APIService.api().get(
    `/message_templates/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const deleteMessage_Templates = (id) => {
  return APIService.api().delete(`/message_templates/${id}`);
};
