import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setSent_MessagesList, setSent_MessagesMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getSent_Messages } from "services/sent_messagesService";
import Layout from "template";
import { Constant } from "template/Constant";
import { Sent_MessagesForm } from "./form";
import { Sent_MessagesTable } from "./table";

export const Sent_Messages: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.sent_messages);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState("");
  const [messageType, setMessageType] = useState<number | null>(null);
  const getData = (page, pageSize, searchKey, messageType?: number) => {
    getSent_Messages(page, pageSize, searchKey, messageType).then(
      (response) => {
        if (response && response.records) {
          dispatch(
            setSent_MessagesList({
              pageNo: page,
              pageSize: pageSize,
              list: response.records,
              totalCount: response.totalCount,
              searchKey: searchKey,
            })
          );
        } else {
          dispatch(
            setSent_MessagesMessage({
              text: "No Record Found",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    );
  };

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(
      setSent_MessagesMessage({ text: "", type: Constant.alertVariantSuccess })
    );
    setAction("edit");
  };
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Отправленные сообщения</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message.text ? (
            <Alert
              variant={rData.message.type}
              className="alert-dismissible fade">
              {rData.message.text}
              <Button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() =>
                  dispatch(
                    setSent_MessagesMessage({
                      text: "",
                      type: Constant.alertVariantSuccess,
                    })
                  )
                }>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert>
          ) : null}
          {action ? (
            <Sent_MessagesForm
              hideShowForm={setAction}
              action={action}
              row={row}
              getData={getData}
            />
          ) : (
            <Sent_MessagesTable
              handleRowEdit={handleRowEdit}
              hideShowForm={setAction}
              getData={getData}
              setMessageType={setMessageType}
              messageType={messageType}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
