import { APIService } from "./index";

export const getTokens = () => {
  return APIService.api().get(`/tokens/my`);
};

export const generateNewToken = () => {
  return APIService.api().post(`/tokens/create`);
};

export const removeToken = (id) => {
  return APIService.api().delete(`/tokens/remove`, {
    data: {
      token_id: id,
    },
  });
};
