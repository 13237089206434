import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form } from "react-bootstrap";
import {
  resetUsersToInit,
  setSender_NamesMessage,
  setUsersList,
} from "redux/actions";

import { getUsers } from "services/usersService";

import { useAppDispatch } from "redux/store";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { addShort_links, updateShort_links } from "services/short_linksService";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};
export const Short_LinksForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: 0,
    shortlink: "",
    users: [],
  };

  const initialValue = action === "edit" ? row : iValue;
  const usersData = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (usersData && usersData.list && usersData.list.length === 0) {
      dispatch(resetUsersToInit());
      getUsers(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setUsersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "No Record Found For Users",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
  }, []);
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (action === "edit") {
        const response = await updateShort_links(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setSender_NamesMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addShort_links(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setSender_NamesMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setSender_NamesMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      shortlink: yup.string().required("Введите ссылку"),
      users: yup
        .array()
        .required("users is required")
        .min(1, "Нужно выбрать хотя бы одного пользователя"),
    }),
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Вернуться назад к списку</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Короткая ссылка</label>
            <Form.Control
              type="text"
              name="shortlink"
              className="form-control"
              value={formik.values.shortlink}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                !!formik.touched.shortlink && !!formik.errors.shortlink
              }
              isValid={!!formik.touched.shortlink && !formik.errors.shortlink}
            ></Form.Control>
            {formik.errors.shortlink && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.shortlink}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <label className="form -control-label">Имя пользователя</label>
            <Form.Control
              as="select"
              name="users"
              className="form-control mb-2"
              multiple
              value={formik.values.user_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.user_id && !!formik.errors.user_id}
              isValid={!!formik.touched.user_id && !formik.errors.user_id}
            >
              {usersData.list.map((item, i) => {
                return (
                  <option value={item.id} key={`users-${i}`}>
                    {item.username}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.user_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.user_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button type="submit" className="float-right" variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
