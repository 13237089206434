import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form, Table } from "react-bootstrap";
import {
  resetCompaniesToInit,
  resetMessage_TypesToInit,
  resetOperatorsToInit,
  setMailingsMessage,
  setMessage_TypesList,
} from "redux/actions";

import { getCompanies } from "services/companiesService";
import { getMessage_Types } from "services/message_typesService";

import { useAppDispatch } from "redux/store";
import {
  addMailings,
  updateMailings,
  sendOnceMessage,
} from "services/mailingsService";
import { Constant } from "template/Constant";
import * as yup from "yup";
import { getModeratedOperators } from "../../services/operatorsService";
import {
  getGroupedSenderNames,
  getSerieses,
} from "../../services/sender_namesService";
import {
  setModeratedOperatorsList,
  setModeratedOperatorsMessage,
} from "../../redux/slices/moderated_operators";
import {
  resetModeratedSenderNamesToInit,
  setModeratedSenderNamesList,
  setModeratedSenderNamesMessage,
} from "../../redux/slices/moderated_sender_names";
import DateTimePicker from "react-datetime-picker";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  getData: (page, pageSize, searchKey) => void;
  action?: string;
};

export const MailingsForm: React.FC<Props> = ({
  row,
  hideShowForm,
  getData,
  action,
}) => {
  const dispatch = useAppDispatch();
  const iValue = {
    id: undefined,
    description: "",
    sender_names: {},
    message_texts: {},
    company_id: undefined,
    scheduled_at: null,
  };

  const iValueOnceMessage = {
    description: "",
    message: "",
    sender_name_id: undefined,
    phone_number: "",
    sender_names: [],
  };

  const initialValueOnceMessage = iValueOnceMessage;
  const initialValue = action === "edit" ? row : iValue;

  const operatorsData = useSelector(
    (state: RootState) => state.moderated_operators
  );
  const senderNamesData = useSelector(
    (state: RootState) => state.moderated_sender_names
  );
  const [companiesData, setCompaniesData] = useState([]);
  const [seriesesData, setSeriesesData] = useState([]);
  const message_typesData = useSelector(
    (state: RootState) => state.message_types
  );
  const message_templatesData = useSelector(
    (state: RootState) => state.message_templates
  );

  const [senderNameValidationSchema, setSenderNameValidationSchema] = useState(
    {}
  );
  const [messagesTextsValidationSchema, setMessagesTextsValidationSchema] =
    useState({});

  useEffect(() => {
    dispatch(resetCompaniesToInit());

    if (companiesData && companiesData.length === 0) {
      dispatch(resetCompaniesToInit());
      getCompanies(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          setCompaniesData(response.records);
        } else {
          dispatch(
            setMailingsMessage({
              text: "No Record Found For Companies",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }

    if (
      operatorsData &&
      operatorsData.list &&
      operatorsData.list.length === 0
    ) {
      dispatch(resetOperatorsToInit());

      getModeratedOperators(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setModeratedOperatorsList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setModeratedOperatorsMessage({
              text: "No Record Found For Operators",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }

    if (
      senderNamesData &&
      senderNamesData.list &&
      senderNamesData.list.length === 0
    ) {
      dispatch(resetModeratedSenderNamesToInit());
      getGroupedSenderNames(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setModeratedSenderNamesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setModeratedSenderNamesMessage({
              text: "No Record Found For Sender Names",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }

    if (seriesesData && seriesesData.length === 0) {
      getSerieses().then((response) => {
        if (response) {
          setSeriesesData(response);
        } else {
          dispatch(
            setModeratedSenderNamesMessage({
              text: "No Record Found For Sender Names",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }
    if (
      message_typesData &&
      message_typesData.list &&
      message_typesData.list.length === 0
    ) {
      dispatch(resetMessage_TypesToInit());
      getMessage_Types(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setMessage_TypesList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setMailingsMessage({
              text: "No Record Found For Message_Types",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }

    // if (message_templatesData && message_typesData.list && message_typesData.list.length === 0) {
    //   dispatch(resetMessage_TypesToInit());
    //   getMessage_Templates(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
    //     if (response && response.records) {
    //       dispatch(setMessage_TypesList({
    //         pageNo: Constant.defaultPageNumber,
    //         pageSize: Constant.defaultDropdownPageSize,
    //         list: response.records,
    //         totalCount: response.total_count,
    //         searchKey: ''
    //       }));
    //     } else {
    //       dispatch(setMailingsMessage({text : "No Record Found For Message_Types", type: Constant.alertVariantDanger}));
    //     }
    //   })
    // }
  }, []);

  useEffect(() => {
    if (
      senderNameValidationSchema &&
      messagesTextsValidationSchema &&
      operatorsData &&
      operatorsData.list.length > 0 &&
      Object.keys(senderNameValidationSchema).length !==
        operatorsData.list.length &&
      Object.keys(messagesTextsValidationSchema).length !==
        operatorsData.list.length
    ) {
      let schema = {};
      let messageTextSchema = {};

      operatorsData.list.forEach((operator) => {
        schema[operator.id] = yup
          .string()
          .test(
            `Sender name for ${operator.operator_name} is required`,
            (val) => val !== "0"
          )
          .required(`Sender name for ${operator.operator_name} is required`);
        messageTextSchema[operator.id] = yup.string();
      });

      setSenderNameValidationSchema(schema);
      setMessagesTextsValidationSchema(messageTextSchema);
    }
  }, [operatorsData]);

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: async (values) => {
      if (isSenderNameSelected) {
        values.sender_names = {};
      } else if (isSelectedAnySenderNamesOperator) {
        values.sender_name_id = undefined;
      }
      if (action === "edit") {
        const response = await updateMailings(row.id, values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setMailingsMessage({
              text: "Обновлено успешно",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setMailingsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      } else if (action === "add") {
        const response = await addMailings(values);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setMailingsMessage({
              text: "Добавлено успешно!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setMailingsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      description: yup.string().required("description это обязательное поле"),
      company_id: yup.string().required("company_id это обязательное поле"),
      // sender_names: yup.object().shape(senderNameValidationSchema),
      message_texts: yup.object().shape(messagesTextsValidationSchema),
      scheduled_at: yup.mixed().nullable(),
    }),
  });


  const formikManual = useFormik({
    initialValues: initialValueOnceMessage,
    onSubmit: async (values) => {
      console.log("test");
      console.log(values);

      if (action === "add-manual") {
        const response = await sendOnceMessage(values);
        console.log(response);
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            setMailingsMessage({
              text: "Сообщение отправлено!",
              type: Constant.alertVariantSuccess,
            })
          );
          getData(Constant.defaultPageNumber, Constant.defaultPageSize, "");
          hideShowForm("");
        } else {
          dispatch(
            setMailingsMessage({
              text: "Ошибка!",
              type: Constant.alertVariantDanger,
            })
          );
        }
      }
    },
    validationSchema: yup.object({
      phone_number: yup.string().required("Поле обязательно для заполнения"),
      message: yup.string().required("Поле обязательно для заполнения"),
      sender_name_id: yup.string().required("Пожалуйста, выберите отправителя"),
    }),
  });

  const handleSenderNamesChange = (operatorId, value) => {
    if (value != 0) {
      formik.setFieldValue(`sender_names.${operatorId}`, value);
    } else {
      const clearedSenderNames = formik.values.sender_names;
      delete clearedSenderNames[operatorId];
      formik.setFieldValue(`sender_names`, clearedSenderNames);
    }
  };


  const isSenderNameSelected =
    !!formik.values.sender_name_id && formik.values.sender_name_id != 0;
  const isSelectedAnySenderNamesOperator =
    Object.keys(formik.values.sender_names).length > 0;

  const newMailingForm = () => {
    return (
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group>
          <label className="form -control-label">Описание</label>
          <Form.Control
            type="text"
            name="description"
            className="form-control"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={
              !!formik.touched.description && !!formik.errors.description
            }
            isValid={!!formik.touched.description && !formik.errors.description}
          ></Form.Control>
          {formik.errors.description && (
            <Form.Control.Feedback type="invalid">
              <>{formik.errors.description}</>
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <label className="form -control-label">Компания</label>
          <Form.Control
            as="select"
            name="company_id"
            className="form-control"
            value={formik.values.company_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={
              !!formik.touched.company_id && !!formik.errors.company_id
            }
            isValid={!!formik.touched.company_id && !formik.errors.company_id}
          >
            <option value={0}>Выберите компании</option>
            {companiesData.map((item, i) => {
              return (
                <option value={item.id} key={`companies-${i}`}>
                  {item.name}
                </option>
              );
            })}
          </Form.Control>
          {formik.errors.company_id && (
            <Form.Control.Feedback type="invalid">
              <>{formik.errors.company_id}</>
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <label className="form -control-label mt-3">Запланировано на</label>
          <div>
            <DateTimePicker
              name="scheduled_at"
              id="scheduled_at"
              value={formik.values.scheduled_at}
              onChange={(e) => {
                console.log(e);
                formik.setFieldValue("scheduled_at", e);
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.errors.scheduled_at && (
            <Form.Control.Feedback type="invalid">
              <>{formik.errors.scheduled_at}</>
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {!isSelectedAnySenderNamesOperator && (
          <Form.Group>
            <label className="form -control-label">Имя отправителя</label>
            <Form.Control
              as="select"
              name="sender_name_id"
              className="form-control mb-2"
              value={formik.values.sender_name_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.sender_name_id}
              isValid={!formik.errors.sender_name_id}
            >
              <option value={0}>Выберите Отправителя</option>
              {senderNamesData.list.map((item, i) => {
                return (
                  <option value={item.id} key={`users-${i}`}>
                    {`${item.sender_name}(${item.id_Value})`}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.sender_name_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.sender_name_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )}
        {!isSenderNameSelected && (
          <>
            <label className="form -control-label mt-4">
              Имена отправителей
            </label>
            <Table>
              <th>Оператор</th>
              <th>Серия</th>
              <th>Текст сообщения</th>
              {operatorsData.list.map((operator, i) => {
                return (
                  <tr>
                    <td>{operator.operator_name}</td>
                    <td>
                      <Form.Group key={operator.id}>
                        <Form.Control
                          as="select"
                          name={`sender_names.${operator.id}`}
                          className="form-control"
                          value={formik.values.sender_names[operator.id]}
                          onChange={(e) =>
                            handleSenderNamesChange(operator.id, e.target.value)
                          }
                          onBlur={formik.handleBlur}
                          // isInvalid={
                          //   !!(formik.touched.sender_names
                          //     ? formik.touched.sender_names[operator.id]
                          //     : false) &&
                          //   !!(formik.errors.sender_names
                          //     ? formik.errors.sender_names[operator.id]
                          //     : false)
                          // }
                          // isValid={
                          //   !!(formik.touched.sender_names
                          //     ? formik.touched.sender_names[operator.id]
                          //     : false) &&
                          //   !(formik.errors.sender_names
                          //     ? formik.errors.sender_names[operator.id]
                          //     : false)
                          // }
                          isValid={true}
                        >
                          <option value={0}>Выберите серию</option>
                          {seriesesData
                            .filter((name) => {
                              if (name.operators !== null) {
                                return name.operators
                                  .split(",")
                                  .map(Number)
                                  .includes(operator.id);
                              } else {
                                return false;
                              }
                            })
                            .filter((name) => {
                              if (
                                JSON.parse(localStorage.userData).is_admin !== 1
                              ) {
                                return (
                                  name.user_id
                                    .split(",")
                                    .map(Number)
                                    .indexOf(
                                      JSON.parse(localStorage.userData).id
                                    ) >= 0
                                );
                              } else {
                                return true;
                              }
                            })
                            .map((item, i) => {
                              return (
                                <option
                                  value={item.id}
                                  key={`message_types-${i}`}
                                >
                                  {"ID: " + item.id + " Текст: " + item.text}
                                </option>
                              );
                            })}
                        </Form.Control>
                        {(formik.errors.sender_names
                          ? formik.errors.sender_names[operator.id]
                          : false) && (
                          <Form.Control.Feedback type="invalid">
                            <>{`Sender name for ${operator.operator_name} is required`}</>
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </>
        )}
        <Form.Group>
          <Button type="submit" className="float-right" variant="primary">
            Сохранить
          </Button>
        </Form.Group>
      </Form>
    );
  };

  const manualForm = () => {
    const formatPhoneNumber = (phoneNumber) => {
      let formattedNumber = phoneNumber.replace(/\D/g, "");
      formattedNumber = "7" + formattedNumber.slice(1);

      return `+${formattedNumber}`;
    };

    return (
      <Form onSubmit={formikManual.handleSubmit}>
        <Form.Group>
          <label className="form -control-label">
            Номер телефона получателя
          </label>
          <Form.Control
            type="tel"
            name="phone_number"
            className="form-control"
            value={formikManual.values.phone_number}
            onChange={(e) => {
              const formattedPhoneNumber = formatPhoneNumber(e.target.value);
              formikManual.handleChange({
                target: { name: "phone_number", value: formattedPhoneNumber },
              });
            }}
            onBlur={formikManual.handleBlur}
            isInvalid={
              !!formikManual.touched.phone_number &&
              !!formikManual.errors.phone_number
            }
            isValid={
              !!formikManual.touched.phone_number &&
              !formikManual.errors.phone_number
            }
          ></Form.Control>
          {formikManual.errors.phone_number && (
            <Form.Control.Feedback type="invalid">
              <>{formikManual.errors.phone_number}</>
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <label className="form -control-label">Выберите отправителя</label>
          <Form.Control
            as="select"
            name="sender_name_id"
            className="form-control"
            value={formikManual.values.sender_name_id}
            onChange={formikManual.handleChange}
            onBlur={formikManual.handleBlur}
            isInvalid={
              !!formikManual.touched.sender_name_id &&
              !!formikManual.errors.sender_name_id
            }
            isValid={
              !!formikManual.touched.sender_name_id &&
              !formikManual.errors.sender_name_id
            }
          >
            <option value={0}>Выберите имя отправителя</option>
            {senderNamesData.list.map((item, i) => {
              return (
                <option value={item.id} key={`message_types-${i}`}>
                  {item.sender_name}
                </option>
              );
            })}
          </Form.Control>
          {formikManual.errors.sender_name_id && (
            <Form.Control.Feedback type="invalid">
              <>{formikManual.errors.sender_name_id}</>
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <label className="form -control-label">Текст сообщения</label>
          <Form.Control
            as="textarea"
            name="message"
            className="form-control"
            value={formikManual.values.message}
            onChange={formikManual.handleChange}
            onBlur={formikManual.handleBlur}
            isInvalid={
              !!formikManual.touched.message && !!formikManual.errors.message
            }
            isValid={
              !!formikManual.touched.message && !formikManual.errors.message
            }
          ></Form.Control>
          {formikManual.errors.message && (
            <Form.Control.Feedback type="invalid">
              <>{formikManual.errors.message}</>
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group>
          <Button type="submit" className="float-right mt-3" variant="primary">
            Отправить
          </Button>
        </Form.Group>
      </Form>
    );
  };

  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "add"
            ? "Добавление рассылки"
            : action === "add-manual"
            ? "Отправка сообщения"
            : "Изменение рассылки"}
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}
          >
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Посмотреть рассылки</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        {action === "add-manual" ? manualForm() : newMailingForm()}
      </Card.Body>
    </Card>
  );
};
