import { APIService } from "services";

export const getCompanies = async (pageNo, pageSize, search) => {
  let res;
  if (search.length === 0) {
    res = await getAllCompanies(pageNo, pageSize);
  } else {
    try {
      res = await searchCompanies(search, pageNo, pageSize);
    } catch (err) {
      return { records: [], totalCount: 0 };
    }
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const getCompaniesDates = async (pageNo, pageSize, dates) => {
  let res;

  try {
    res = await searchCompaniesDates(dates, pageNo, pageSize);
  } catch (err) {
    return { records: [], totalCount: 0 };
  }
  if (res && res.data) {
    return res.data;
  } else {
    return { records: [], totalCount: 0 };
  }
};

export const addCompanies = (data) => {
  return APIService.api().post(`/companies`, data);
};
export const updateCompanies = (id, data) => {
  return APIService.api().patch(`/companies/${id}`, data);
};
export const getAllCompanies = (pageNo, pageSize) => {
  return APIService.api().get(
    `/companies/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const getOneCompanies = (id) => {
  return APIService.api().get(`/companies/${id}`);
};
export const searchCompanies = (searchKey, pageNo, pageSize) => {
  return APIService.api().get(
    `/companies/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const searchCompaniesDates = (dates, pageNo, pageSize) => {
  let d1 = new Date(dates[0]);
  let datestring1 =
    d1.getFullYear() +
    "-" +
    (d1.getMonth() + 1) +
    "-" +
    d1.getDate() +
    " " +
    "00:00:00";
  let d2 = new Date(dates[1]);
  let datestring2 =
    d2.getFullYear() +
    "-" +
    (d2.getMonth() + 1) +
    "-" +
    d2.getDate() +
    " " +
    "00:00:00";

  return APIService.api().get(
    `/companies/searchDates/${datestring1}/${datestring2}/?pageNo=${pageNo}&pageSize=${pageSize}`
  );
};
export const deleteCompanies = (id) => {
  return APIService.api().delete(`/companies/${id}`);
};
