import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IOperators {
  id: number;
  operator_name: string;
}

interface IModeratedOperatorsData {
  list?: Array<IOperators>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: { text: string; type: string };
}

const initialState: IModeratedOperatorsData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: "",
  list: [],
  totalCount: 0,
  message: { text: "", type: "" },
};

const moderatedOperatorsSlice = createSlice({
  name: "operators",
  initialState,
  reducers: {
    setModeratedOperatorsList: (
      state,
      _action: PayloadAction<IModeratedOperatorsData>
    ) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetModeratedOperatorsToInit: (state) => {
      Object.assign(state, initialState);
    },
    setModeratedOperatorsMessage: (
      state,
      _action: PayloadAction<{ text: string; type: string }>
    ) => {
      state.message = _action.payload;
    },
  },
});

export const {
  setModeratedOperatorsList,
  resetModeratedOperatorsToInit,
  setModeratedOperatorsMessage,
} = moderatedOperatorsSlice.actions;

export default moderatedOperatorsSlice.reducer;
