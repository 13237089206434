import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

import { Button, Card, Form } from "react-bootstrap";
import {
  resetUsersToInit,
  setTransactionsMessage,
  setUsersList,
  setUsersMessage,
} from "redux/actions";

import { useAppDispatch } from "redux/store";
import { getUsers, setBalance } from "services/usersService";
import { Constant } from "template/Constant";

type Props = {
  row?: any;
  hideShowForm: (actionName) => void;
  action?: string;
};
export const TransferForm: React.FC<Props> = ({ hideShowForm, action }) => {
  const dispatch = useAppDispatch();
  const iValue = { id: 0, username: "", balance: "", user_id: "" };
  const transferData = useSelector((state: RootState) => state.users);

  useEffect(() => {
    setTimeout(function () {
      dispatch(resetUsersToInit());
      getUsers(
        Constant.defaultPageNumber,
        Constant.defaultDropdownPageSize,
        ""
      ).then((response) => {
        if (response && response.records) {
          dispatch(
            setUsersList({
              pageNo: Constant.defaultPageNumber,
              pageSize: Constant.defaultDropdownPageSize,
              list: response.records,
              totalCount: response.total_count,
              searchKey: "",
            })
          );
        } else {
          dispatch(
            setTransactionsMessage({
              text: "No Record Found For Users",
              type: Constant.alertVariantDanger,
            })
          );
        }
      });
    }, 1000);
  }, []);

  const formik = useFormik({
    initialValues: iValue,
    onSubmit: async (values) => {
      console.log({ balance: values.balance, user_id: values.user_id });
      const response = await setBalance(values.user_id, {
        balance: values.balance,
        user_id: values.user_id,
      });
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch(
          setUsersMessage({
            text: "Обновлено успешно",
            type: Constant.alertVariantSuccess,
          })
        );
        hideShowForm("");
        window.location.reload();
      } else {
        dispatch(
          setUsersMessage({
            text: "Ошибка!",
            type: Constant.alertVariantDanger,
          })
        );
      }
    },
  });
  return (
    <Card className="shadow mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold text-primary text-capitalize">
          {action === "add" ? "добавление" : ""} Баланса
          <Button
            className="btn-icon-split float-right"
            onClick={() => hideShowForm(false)}>
            <span className="icon text-white-50">
              <i className="fas fa-list"></i>
            </span>
            <span className="text">Просмотр пользователей</span>
          </Button>
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <label className="form -control-label">Баланс</label>
            <Form.Control
              type="text"
              name="balance"
              className="form-control"
              value={formik.values.balance}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.balance && !!formik.errors.balance}
              isValid={
                !!formik.touched.balance && !formik.errors.balance
              }></Form.Control>

            <label className="form -control-label">Пользователь</label>

            <Form.Control
              as="select"
              name="user_id"
              className="form-control"
              value={formik.values.user_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.touched.user_id && !!formik.errors.user_id}
              isValid={!!formik.touched.user_id && !formik.errors.user_id}>
              <option value={0}>Выберите пользователей</option>
              {transferData.list.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    key={`users-${i}`}>
                    {item.username}
                  </option>
                );
              })}
            </Form.Control>
            {formik.errors.user_id && (
              <Form.Control.Feedback type="invalid">
                <>{formik.errors.user_id}</>
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <Button
              type="submit"
              className="float-right"
              variant="primary">
              Сохранить
            </Button>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};
