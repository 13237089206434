import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IProxies {
  id: number,
  protocol: string,
  host: string,
  login: string,
  password: string
}

interface IProxiesData {
  list?: Array<IProxies>;
  pageNo: number;
  pageSize: number;
  searchKey?: string;
  totalCount?: number;
  message?: string;
}

const initialState: IProxiesData = {
  pageNo: 1,
  pageSize: 20,
  searchKey: '',
  list: [],
  totalCount: 0,
  message: '',
};

const proxiesSlice = createSlice({
  name: "proxies",
  initialState,
  reducers: {
    setProxiesList: (state, _action: PayloadAction<IProxiesData>) => {
      state.list = _action.payload.list;
      state.pageNo = _action.payload.pageNo;
      state.pageSize = _action.payload.pageSize;
      state.totalCount = _action.payload.totalCount;
    },
    resetProxiesToInit: (state) => {
      state = initialState;
    },
    setProxiesMessage: (state, _action: PayloadAction<string>) => {
      state.message = _action.payload;
    },
  },
});

export const {setProxiesList, resetProxiesToInit, setProxiesMessage} = proxiesSlice.actions;

export default proxiesSlice.reducer;

